import { FormEvent, useState, useRef, useEffect } from 'react';

// Helpers
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

// Helpers
import localizer from 'src/localization/localizer';
import { Lead } from '@prompto-api';
import { buildAssetURIWithOptions, sendLeadToMeta } from 'src/helpers/uri';
import {
  IContentItem,
  Tracking,
  GetInTouchActionNameType,
  GetInTouchLocationOnPageType,
  TrackGetInTouchBasicParams
} from '@/libs/prompto-api/src';
import { useDataState } from 'src/store/DataStore';
import { useAppState } from 'src/store/AppStore';
import { useContactUsState } from 'src/store/ContactUsStore';
import { isMobile } from 'react-device-detect';
import { useAuthState } from 'src/store/AuthStore';

// Components
import Button from '../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInputField from 'src/components/ PhoneInputField';
import SubmitFormResultModal from 'src/components/getInTouchForm/SubmitFormResultModal';
import InfoIcon from './InfoIcon';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

export interface IModalContent {
  buttonText: string;
  icon: string;
  header: string;
  paragraph1: string;
  paragraph2?: string;
  success: boolean;
}

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const BackdropOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(46, 49, 56, 0.3);
  z-index: 3008;
`;

const Center = styled.div`
  position: fixed;
  height: 100dvh;
  width: 100dvw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3008;
`;

export const Modal = styled.div`
  position: relative;
  padding: 24px 32px 32px 32px;
  padding-bottom: 12px;

  background-color: white;
  z-index: 2000;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  ${forBiggerThan.tablet`
  max-height: 520px;
  max-width: 1128px;
  border-radius: 8px;
  `}

  ${forBiggerThan.laptop`
  max-height: 820px;
  max-width: 1128px;
  border-radius: 8px;
  `}
`;

const NonModal = styled.div`
  padding: 16px 20px;
  position: relative;
  background-color: white;
  align-items: center;
  margin: 0 16px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  ${forBiggerThan.desktop`
  padding: 96px 210px 66px;
  margin: 0;
  `}

  ${forBiggerThan.largeDesktop`
  padding: 96px 300px 66px;
  margin: 0;
  `}
`;

const ContentWrapper = styled.div<StyledProps<{ isModal?: boolean }>>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas:
    'Project'
    'Form';

  ${forBiggerThan.tablet`
  display: flex;
    flex-flow: row;
  `}
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  column-gap: 32px;
  flex-wrap: wrap;
  ${forBiggerThan.tablet`
    & > div {
      width: calc(50% - 16px) // 16px - the half of the gap
    }
  `}
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 24px;
`;

const YourMessageWrapper = styled(VerticalWrapper)`
  margin-bottom: 0;
`;

const ModalRightPart = styled(VerticalWrapper)`
  grid-area: Project;
  flex-basis: 40%;
`;

const EmailAddressWrapper = styled(VerticalWrapper)`
  margin-bottom: 24px;
`;

const MultiChoiceOption = styled.label`
  display: flex;
  flex-flow: row;
  column-gap: 12px;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  cursor: pointer;
  ${forBiggerThan.laptop`
    & > div {
      width: calc(50% - 16px) // 16px - the half of the gap
    }
  `}

  &:first-child {
    margin-top: 16px;
  }
  &:nth-child(2) {
    margin-bottom: 4px;
  }
`;

const MultiChoiceOptionWithInstruction = styled(MultiChoiceOption)`
  align-items: center;
  &:first-child {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  &:nth-child(2) {
    margin-bottom: 0px;
  }
  ${forBiggerThan.laptop`
    & > div {
      width: auto;
    }
  `}
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const MultiChoiceWrapper = styled(VerticalWrapper)`
  margin-bottom: 24px;
`;

const FormWrapper = styled.form`
  grid-area: Form;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  flex-basis: 60%;
`;

const Title = styled.div<StyledProps<{ isModal?: boolean }>>`
  font-size: ${({ isModal }) => (isModal ? '1.5rem' : '2rem')};
  font-weight: ${({ isModal }) => (isModal ? 'normal' : 'bold')};
  font-style: ${({ isModal }) => (isModal ? 'normal' : 'italic')};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  margin-bottom: 23px;
`;

export const CloseButton = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 12px;
  right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1.5rem;
`;

const Label = styled.div`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 4px;
  box-sizing: border-box;
`;

const MultiChoiceOptionText = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
`;

const MultiChoiceOptionTextWithInfoIcon = styled(MultiChoiceOptionText)`
  display: flex;
  flex-direction: row;
  gap: ${isMobile ? 4 : 12}px;
`;

const InputField = styled.input`
  min-width: 150px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray300};
  padding: 14px 12px;
  outline: inherit;
`;

const StyledCheckbox = styled(Field)`
  width: 15px;
  height: 15px;
  accent-color: ${({ theme }) => theme.gold};
  margin-right: 8px;
  mix-blend-mode: multiply;
  cursor: pointer;
  flex-shrink: 0;
`;

const MessageField = styled.textarea`
  min-height: 108px;
  max-height: 108px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray300};
  padding: 14px 12px;
  outline: inherit;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  resize: none;
`;

const Divider = styled.div<StyledProps<{ isModal?: boolean }>>`
  background: ${({ theme }) => theme.gray20};
  width: 100%;
  height: 1px;
  margin: 24px 0;
  ${({ isModal }) => {
    return forBiggerThan.tablet`
      width: 1px;
      height: 100%;
      margin: ${isModal ? '0 24px' : '0 48px'};
  `;
  }}
`;

const ProjectThumbnail = styled.img`
  width: 100%;
  min-width: 250px;
`;

const SubmitButton = styled(Button)<
  StyledProps<{ isSubmitting?: boolean; disabled?: boolean }>
>`
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  background: ${({ theme }) => theme.greenCTA};
  transition: all 0.3s;
  min-height: 48px;
  margin: 5px;
  ${({ isSubmitting, disabled, theme }) => {
    if (isSubmitting || disabled) {
      return css`
        background: ${theme.gray30};
      `;
    }
  }};
`;

const ProjectTitle = styled.div<StyledProps<{ isModal?: boolean }>>`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: ${({ isModal }) => (isModal ? '1.875rem' : '1.75rem')};
  color: ${({ theme }) => theme.black};
  margin-top: 24px;
  margin-bottom: 8px;
`;

const ProjectAddress = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
  width: auto !important;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.redBright};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.gray50};
  margin-right: 8px;
  margin-top: 3px;
`;

const DescriptionCount = styled.div`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
  margin-left: auto;
  margin-top: 4px;
`;

const AdditionalChecksWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 4px;
`;

const NewsLetterCheck = styled(MultiChoiceOption)`
  margin-top: 0 !important;
  flex-wrap: nowrap;
`;

const PrivacyCheck = styled(MultiChoiceOption)`
  width: 100% !important;
  flex-wrap: nowrap;
`;

const CheckWrapper = styled.div`
  width: 5% !important;
`;

const NewsLetterText = styled(MultiChoiceOptionText)`
  width: 90%;
`;

const NewsLetterCheckbox = styled(StyledCheckbox)``;

const PrivacyText = styled.span`
  width: 90%;
`;

const PrivacyCheckbox = styled(StyledCheckbox)``;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.gold};
`;

const ExtraInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.gray60};
  margin-top: 8px;

  ${forBiggerThan.tablet`
  margin-top: auto;
  margin-bottom: 8px;
  `}
`;

const MultiChoiceWithInstructionWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const phoneRegExp = /^\+?\d*$/;
const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  postcode: '',
  reason: [],
  phoneNumber: '',
  yourMessage: '',
  newsletter: true,
  privacyPolicy: false,
  visitorObjectId: '',
  vaultId: '',
  projectId: ''
};

interface FormProps {
  onClose?: () => void;
  isModal?: boolean;
  unitObjectId?: string;
  projectThumbnail: IContentItem;
  projectTitle?: string;
  projectAddress: string;
  trackingData?: TrackGetInTouchBasicParams;
  location: GetInTouchLocationOnPageType;
}

Yup.addMethod(Yup.string, 'validatePhoneNumber', function (payload: any) {
  const { errorMessage } = payload;
  // @ts-ignore
  return this.test('validatePhoneNumber', errorMessage, function (value) {
    // @ts-ignore
    const { createError } = this;

    let isValid = false;
    const code = value ? value.slice(0, 2) : '';

    switch (code) {
      case '32':
        isValid = value.length === 11;
        break;
      case '04':
        isValid = value.length === 10;
        break;
      default:
        isValid = value ? value.length > 9 && value.length < 15 : false;
    }

    return isValid || createError(errorMessage);
  });
});

const GetInTouchForm = ({
  isModal = true,
  unitObjectId,
  projectTitle,
  projectAddress,
  projectThumbnail,
  trackingData,
  location,
  onClose
}: FormProps) => {
  const [mustSubmitForm, setMustSubmitForm] = useState(false);
  const [resultModalOpened, setResultModalOpened] = useState(false);
  const [resultModalContent, setResultModalContent] = useState<any>();
  const [isInteractingTimeout, setIsInteractingTimeout] = useState<any>();
  const [isInteractionTracked, setInteractionTracked] = useState(false);
  const [isFormTouched, setFormTouched] = useState(false);

  // App state
  const { AppState } = useAppState();
  const { marketplaceId, utmCampaign, utmSource } = AppState;

  const { DataState } = useDataState();
  const { currentVaultId, currentProjectId } = DataState;

  const { ContactUsState, ContactUsStateDispatch } = useContactUsState();
  const { contactFormDetails } = ContactUsState;

  const modalRef = useRef(null);

  const { AuthState } = useAuthState();
  const { user, vault } = AuthState;

  useEffect(() => {
    if (user) {
      ContactUsStateDispatch({
        type: 'setContactDetails',
        payload: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          postcode: vault?.billingAddress?.zipCode ?? ''
        }
      });
    }
  }, []);

  // Tracking
  const trackAction = (actionName: GetInTouchActionNameType) => {
    if (trackingData) {
      Tracking.trackGetInTouchInteraction({
        ...trackingData,
        locationOnPage: location,
        actionName
      }).catch(() => console.warn(`Could not track ${actionName}`));
    }
  };

  const storeData = (v: any, e: any) => {
    const updatedValues: any = v;
    updatedValues[e.target.name] = e.target.value;

    clearTimeout(isInteractingTimeout);

    setIsInteractingTimeout(
      setTimeout(() => {
        ContactUsStateDispatch({
          type: 'setContactDetails',
          payload: updatedValues
        });
        if (!isInteractionTracked) {
          trackAction('formInteracted');
          setInteractionTracked(true);
        }
      }, 1000)
    );
  };

  const [descriptionCharacterCount, setDescriptionCharacterCount] =
    useState<number>(0);

  return (
    <Formik
      initialValues={defaultFormValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .required(localizer.getInTouchForm.formErrors.firstNameRequired)
          .max(30, localizer.getInTouchForm.formErrors.firstNameTooLong)
          .min(2, localizer.getInTouchForm.formErrors.firstNameIsShort)
          .matches(
            /[^\s*].*[^\s*]/g,
            localizer.getInTouchForm.formErrors.noBlankSpacesOnly
          ),
        lastName: Yup.string()
          .required(localizer.getInTouchForm.formErrors.lastNameRequired)
          .max(30, localizer.getInTouchForm.formErrors.lastNameTooLong)
          .min(2, localizer.getInTouchForm.formErrors.lastNameIsShort)
          .matches(
            /[^\s*].*[^\s*]/g,
            localizer.getInTouchForm.formErrors.noBlankSpacesOnly
          ),
        email: Yup.string()
          .matches(
            emailRegExp,
            localizer.getInTouchForm.formErrors.invalidEmailAddress
          )
          .required(localizer.getInTouchForm.formErrors.emailRequired),
        postcode: Yup.string()
          .required(localizer.getInTouchForm.formErrors.postcodeRequired)
          .matches(
            /[^\s*].*[^\s*]/g,
            localizer.getInTouchForm.formErrors.noBlankSpacesOnly
          ),
        phoneNumber: Yup.string()
          .matches(
            phoneRegExp,
            localizer.getInTouchForm.formErrors.invalidPhoneNumber
          )
          .validatePhoneNumber({
            errorMessage: localizer.getInTouchForm.formErrors.invalidPhoneNumber
          })
          .matches(
            /[^\s*].*[^\s*]/g,
            localizer.getInTouchForm.formErrors.noBlankSpacesOnly
          )
          .required(localizer.getInTouchForm.formErrors.phoneNumberRequired),
        reason: Yup.array().test(
          'atLeastOneSelected',
          localizer.getInTouchForm.formErrors.selectAtLeastOneOption,
          (value: Array<string>) => {
            return value.length > 0;
          }
        ),
        yourMessage: Yup.string(),
        utmSource: Yup.string(),
        privacyPolicy: Yup.boolean().oneOf(
          [true],
          localizer.getInTouchForm.formErrors.agreeToTerms
        )
      })}
      onSubmit={(values, restProps) => {
        const { setSubmitting, setStatus, resetForm } = restProps;
        setMustSubmitForm(false);
        // track that user submit a form, both successfully or not
        trackAction('formSubmitted');

        setSubmitting(true);

        Lead.create({
          leadCreateMarketplaceParams: {
            ...values,
            language: localizer.getLanguage()
          }
        })
          .then(() => {
            setStatus('success');
            setSubmitting(false);

            //@ts-ignore
            sendLeadToMeta(values.email, values.phone);

            resetForm();
          })
          .catch((e) => {
            // Any error we get from Hubspot can be ignored, the lead is created but there was only an issue sending the lead to hubspot. This is not an error that concerns the user who filled in the form
            if (e?.response?.data?.code === 93701) {
              setStatus('success');
              setSubmitting(false);
              resetForm();
            } else {
              setStatus('failed');
              setSubmitting(false);
            }
          });
      }}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        setValues,
        status,
        values,
        isSubmitting,
        isValid,
        touched,
        validateForm,
        submitForm,
        setStatus
      }) => {
        const sharedInputProps = {
          onChange: (e: any) => {
            storeData(values, e);
            handleChange(e);
          },
          onBlur: (e: any) => {
            storeData(values, e);
            handleChange(e);
          },
          setFieldValue
        };

        const modalSuccessContent = {
          icon: 'comment-alt-check',
          header: localizer.getInTouchForm.submitResult.thankYou,
          paragraph1:
            localizer.getInTouchForm.submitResult.successDescriptionIntro,
          buttonText: localizer.getInTouchForm.submitResult.done,
          success: true
        };

        const modalFailContent = {
          icon: 'exclamation-triangle',
          header: localizer.getInTouchForm.submitResult.errorOccurred,
          paragraph1:
            localizer.getInTouchForm.submitResult.failDescriptionIntro,
          paragraph2:
            localizer.getInTouchForm.submitResult.failDescriptionOutro,
          buttonText: localizer.getInTouchForm.submitResult.gotIt,
          success: false
        };

        if (status) {
          if (status === 'success') {
            ContactUsStateDispatch({
              type: 'resetContactForm'
            });
          }
          setResultModalOpened(true);
          setResultModalContent(
            status === 'success' ? modalSuccessContent : modalFailContent
          );
          setStatus('');
        }

        setFormTouched(Object.keys(touched).length > 0);

        if (!isInteractingTimeout) {
          let valuesToChange: any = { ...values };
          let needsUpdate = false;

          if (values.firstName !== contactFormDetails.firstName) {
            valuesToChange['firstName'] = contactFormDetails.firstName;
            needsUpdate = true;
          }

          if (values.lastName !== contactFormDetails.lastName) {
            valuesToChange['lastName'] = contactFormDetails.lastName;
            needsUpdate = true;
          }

          if (values.email !== contactFormDetails.email) {
            valuesToChange['email'] = contactFormDetails.email;
            needsUpdate = true;
          }

          if (values.postcode !== contactFormDetails.postcode) {
            valuesToChange['postcode'] = contactFormDetails.postcode;
            needsUpdate = true;
          }
          if (values.phoneNumber !== contactFormDetails.phoneNumber) {
            valuesToChange['phoneNumber'] = contactFormDetails.phoneNumber;
            needsUpdate = true;
          }
          if (values.yourMessage !== contactFormDetails.yourMessage) {
            valuesToChange['yourMessage'] = contactFormDetails.yourMessage;
            needsUpdate = true;
          }

          if (needsUpdate) {
            setValues(valuesToChange);
          }
        }

        const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();

          if (!isFormTouched) {
            validateForm();
            return;
          }

          const formatPhone = (phoneNumber: string) => {
            if (phoneNumber.startsWith('32')) {
              return `+${phoneNumber}`;
            }
            return phoneNumber;
          };

          const visitorObjectId = trackingData?.visitorId;
          const formAdditionalValues: any = {
            ...values,
            phone: formatPhone(values.phoneNumber),
            message: values.yourMessage,
            contactReasonSet: values.reason,
            vaultId: currentVaultId,
            projectId: currentProjectId,
            agreedToBeContactedByPrompto: values.newsletter,
            marketplaceId: marketplaceId
          };
          formAdditionalValues.name = values.firstName + ' ' + values.lastName;
          if (visitorObjectId) {
            formAdditionalValues.visitorObjectId = visitorObjectId;
          }
          if (utmSource) {
            formAdditionalValues.utmSource = utmSource;
          }
          if (utmCampaign) {
            formAdditionalValues.utmCampaign = utmCampaign;
          }
          if (unitObjectId) {
            formAdditionalValues.unitObjectIds = [unitObjectId];
          }
          setValues(formAdditionalValues, false);
          if (isValid) {
            submitForm();
          }
        };

        const formContent = (
          <>
            <Title isModal={isModal}>{localizer.getInTouchForm.title}</Title>
            <ContentWrapper isModal={isModal}>
              {isModal && onClose && (
                <CloseButton onClick={onClose}>
                  <CloseIcon icon={['fal', 'times']} size="sm" />
                </CloseButton>
              )}
              <FormWrapper onSubmit={handleFormSubmit}>
                <Label>{localizer.getInTouchForm.reasonForContactLabel}</Label>
                <MultiChoiceWrapper>
                  <MultiChoiceWithInstructionWrapper>
                    <MultiChoiceOptionWithInstruction>
                      <StyledCheckbox
                        type="checkbox"
                        name={'reason'}
                        value={'askToScheduleVisit'}
                      />
                      <MultiChoiceOptionTextWithInfoIcon>
                        {localizer.getInTouchForm.contactReasons.scheduleVisit}
                      </MultiChoiceOptionTextWithInfoIcon>
                    </MultiChoiceOptionWithInstruction>

                    <InfoIcon
                      tippyText={
                        localizer.getInTouchForm.contactReasons
                          .scheduleVisitTooltipText
                      }
                      dataTestId="schedule-visit-icon"
                      parentRef={modalRef}
                    />
                  </MultiChoiceWithInstructionWrapper>

                  <MultiChoiceWithInstructionWrapper>
                    <MultiChoiceOptionWithInstruction>
                      <StyledCheckbox
                        type="checkbox"
                        name={'reason'}
                        value={'askForMoreInfo'}
                      />
                      <MultiChoiceOptionTextWithInfoIcon>
                        {localizer.getInTouchForm.contactReasons.moreInfo}
                      </MultiChoiceOptionTextWithInfoIcon>
                    </MultiChoiceOptionWithInstruction>
                    <InfoIcon
                      tippyText={
                        localizer.getInTouchForm.contactReasons
                          .moreInfoTooltipText
                      }
                      dataTestId="request-more-info-icon"
                      parentRef={modalRef}
                    />
                  </MultiChoiceWithInstructionWrapper>
                  <ErrorMessage>{errors.reason}</ErrorMessage>
                </MultiChoiceWrapper>
                <HorizontalWrapper>
                  <VerticalWrapper>
                    <Label>{`${localizer.getInTouchForm.firstNameLabel} *`}</Label>
                    <InputField
                      name={'firstName'}
                      value={values.firstName}
                      {...sharedInputProps}
                    />
                    <ErrorMessage>{errors.firstName}</ErrorMessage>
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Label>{`${localizer.getInTouchForm.lastNameLabel} *`}</Label>
                    <InputField
                      name={'lastName'}
                      value={values.lastName}
                      {...sharedInputProps}
                    />
                    <ErrorMessage>{errors.lastName}</ErrorMessage>
                  </VerticalWrapper>
                </HorizontalWrapper>
                <EmailAddressWrapper>
                  <Label>{`${localizer.getInTouchForm.emailAddressLabel} *`}</Label>
                  <InputField
                    name={'email'}
                    value={values.email}
                    {...sharedInputProps}
                  />
                  <ErrorMessage>{errors.email}</ErrorMessage>
                </EmailAddressWrapper>
                <HorizontalWrapper>
                  <VerticalWrapper>
                    <Label>{`${localizer.getInTouchForm.postcodeLabel} *`}</Label>
                    <InputField
                      name={'postcode'}
                      value={values.postcode}
                      {...sharedInputProps}
                    />
                    <ErrorMessage>{errors.postcode}</ErrorMessage>
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Label>{`${localizer.getInTouchForm.phoneNumberLabel} *`}</Label>
                    <PhoneInputField
                      name="phoneNumber"
                      currentLanguage={localizer.getLanguage()}
                      placeholder={localizer.getInTouchForm.phoneNumberLabel}
                      value={values.phoneNumber}
                      onChange={(value) => {
                        storeData(values, {
                          target: {
                            name: 'phoneNumber',
                            value
                          }
                        });
                        setFieldValue('phoneNumber', value);
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
                  </VerticalWrapper>
                </HorizontalWrapper>
                <YourMessageWrapper>
                  <Label>{localizer.getInTouchForm.messageLabel}</Label>
                  <MessageField
                    name={'yourMessage'}
                    value={values.yourMessage}
                    maxLength={512}
                    onChange={(e: any) => {
                      storeData(values, {
                        target: {
                          name: 'yourMessage',
                          value: e.target.value
                        }
                      });
                      setDescriptionCharacterCount(e.target.value.length);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.yourMessage && (
                    <ErrorMessage>{errors.yourMessage}</ErrorMessage>
                  )}
                  <DescriptionCount>{`${descriptionCharacterCount}/512`}</DescriptionCount>
                </YourMessageWrapper>
                <AdditionalChecksWrapper>
                  <NewsLetterCheck>
                    <CheckWrapper>
                      <NewsLetterCheckbox type="checkbox" name={'newsletter'} />
                    </CheckWrapper>
                    <NewsLetterText>
                      {localizer.getInTouchForm.optInOnNewsletter}
                    </NewsLetterText>
                  </NewsLetterCheck>
                  <PrivacyCheck>
                    <CheckWrapper>
                      <PrivacyCheckbox type="checkbox" name={'privacyPolicy'} />
                    </CheckWrapper>
                    <PrivacyText>
                      {`${localizer.getInTouchForm.privacyPolicy1} `}
                      <PrivacyLink
                        href="https://promptogroup.com/privacy-policy-2/"
                        target="_blank"
                      >
                        {localizer.getInTouchForm.privacyPolicy}
                      </PrivacyLink>
                      {` ${localizer.getInTouchForm.privacyPolicy2}*`}
                    </PrivacyText>
                  </PrivacyCheck>
                  <ErrorMessage>{errors.privacyPolicy}</ErrorMessage>
                </AdditionalChecksWrapper>
                <SubmitButton
                  type="submit"
                  disabled={!isValid || isSubmitting || mustSubmitForm}
                  isSubmitting={isSubmitting}
                >
                  <>
                    {isSubmitting ? (
                      <Icon icon={['fal', 'spinner']} size="sm" pulse />
                    ) : null}
                    {localizer.getInTouchForm.submitButtonText}
                  </>
                </SubmitButton>
              </FormWrapper>
              <Divider isModal={isModal} />
              <ModalRightPart>
                <ProjectThumbnail
                  src={buildAssetURIWithOptions(
                    'q=100',
                    projectThumbnail?.contentUri ?? ''
                  )}
                />
                <ProjectTitle isModal={isModal}>{projectTitle}</ProjectTitle>
                <AddressWrapper>
                  <StyledIcon icon={['fas', 'map-marker-alt']} size="sm" />
                  <ProjectAddress>{projectAddress}</ProjectAddress>
                </AddressWrapper>
                <ExtraInfo>{localizer.getInTouchForm.extraInfo}</ExtraInfo>
              </ModalRightPart>
            </ContentWrapper>
          </>
        );

        // show overlay for both modals - contact us and submission results too
        const showOverlay = isModal || resultModalOpened;

        return (
          <>
            {showOverlay && <BackdropOverlay onClick={onClose} />}
            {isModal ? (
              !resultModalOpened && (
                <Center
                  onMouseDown={(e) => {
                    onClose && onClose();
                  }}
                >
                  <Modal
                    ref={modalRef}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {formContent}
                  </Modal>
                </Center>
              ) // if modal - contact us form should be hidden to show submission results
            ) : (
              <NonModal ref={modalRef}>{formContent}</NonModal> // if not a modal - it's not needed to hide anything
            )}
            {resultModalOpened ? (
              <SubmitFormResultModal
                onClose={() => {
                  setResultModalOpened(false);
                  onClose && onClose();
                }}
                modalContent={resultModalContent}
              />
            ) : null}
          </>
        );
      }}
    </Formik>
  );
};

export default GetInTouchForm;

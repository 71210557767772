const env = {
  env: 'development',
  baseImageUrl: 'https://devimagecdn.prompto.com',
  googleMapsApiKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',
  base360ImageUrl: 'https://devimagecdn.around.media',
  googleStorageBucketId: 'devassetsvxgi.vr-tual.media',
  cloudinaryVideoBaseUrl: 'https://res.cloudinary.com/prompto-dev/video/upload',
  base360Url: 'https://devapi.around.media/v1',
  apiToken360:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxNmMwNGU4MS1jYTdhLTQxNmItYmMyMC0yNWM2ZWI3MDUxODEiLCJpYXQiOjE0Njc3MjE0MzQsImV4cCI6MjA5ODQ0MTQzNCwiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.ZjB3XiiQEjcguILhBUB8LzQD2Dcd4ssakiEsHZ_ISByn-McCdVM8EFTluP4RUxLSgytgq3GrNC93PYX3q68oWQ',
  apiToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI2ZTM0ZjliNC03NmUwLTRkYmMtYTBiYy1jODQ0YjBlMzY2NzUiLCJpYXQiOjE0ODczMTgyODEsImV4cCI6MjExODAzODI4MSwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.uwAmQwF2XUa4VFnFwnpB2XRi1AWC0pGuy7uoFmGjXHXA5OFgG7ucMj_k9JV1KemK2Z8jFqRLz3yDcsY8ZTWI5w',
  baseUrl: 'https://devapiv2.prompto.com/v2',
  cloudinaryCloudName: 'prompto-dev',
  cloudinaryImageBaseUrl: 'https://res.cloudinary.com/prompto-dev/image/upload',
  homePageBgImageUrl:
    'https://promptogroup.com/wp-content/uploads/2023/01/Prompto_Erasmus_Gardens_INT02-2048x1152.jpg',
  termsOfService: 'https://promptogroup.com/terms-of-service/',
  privacyPolicy: 'https://promptogroup.com/privacy-policy-2/',
  cookiePolicy: 'https://promptogroup.com/cookie-policy/',
  linkedinUrl: 'https://www.linkedin.com/company/prompto-com',
  instagramUrl: 'https://www.instagram.com/prompto_newbuild/',
  aboutPrompto: 'https://promptogroup.com/about/',
  contactUs: 'https://promptogroup.com/contact-us/',
  leadGenerationUrl: 'https://promptogroup.com/lead-generation/',
  marketplaceUrl: 'https://dev.prompto.com',
  facebookAccessToken:
    'EAAHA4u8XK4kBOxxc14kDrHkoZAU1tvdVmb7GVvTA1p4ZCGu7pRYUyxuPhiLbM1iwQUOVSdZCmbGkKUFrZCyR31NG8y6UUeyN0EpCCSyZCPZCzZAE6OPMosraxGJ5QlDQiLfkOsUZB4K0gLhwXZBq9yRUjlKyYNUJpNZCiZBjjCTAiqEI6Lr0d630kO2WaTpKXPE7OAt0QZDZD',
  firebaseApiKey: 'AIzaSyC5s-o_8wGy5qp7LSIyl7r0NJQhecw3sHc',
  firebaseAuthDomain: 'vrtual-media-api-dev-v2.firebaseapp.com',
  firebaseProjectId: 'vrtual-media-api-dev-v2',
  firebaseStorageBucket: 'vrtual-media-api-dev-v2.appspot.com',
  firebaseMessagingSenderId: '499314241102',
  firebaseAppId: '1:499314241102:web:2feb869bb6c584ae789d1f',
  firebaseMeasurementId: ''
};

export default env;

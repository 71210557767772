const env = {
  env: 'production',
  baseImageUrl: 'https://imagecdn.prompto.com',
  googleMapsApiKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',
  base360ImageUrl: 'https://imagecdn.around.media',
  googleStorageBucketId: 'assetsvxgi.vr-tual.media',
  cloudinaryVideoBaseUrl: 'https://res.cloudinary.com/prompto/video/upload',
  base360Url: 'https://api.around.media/v1',
  apiToken360:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI0NDRmMjBhZi04NWFiLTQzYWEtOTJkOC1lZTk1ZWIyYzVkM2QiLCJpYXQiOjE0Njk1MjAzMTMsImV4cCI6MjEwMDI0MDMxMywiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.DLrC1mSZGo0kl2eVPd5iJEpfFo8UBaiD6gGF-5pn9yec7XnN9QOvCe_NoiltCMjQaLK3Dy7F4j27oCXn-0sBBg',
  apiToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIyODE3OTE2Zi05YzliLTRiZWQtOTljZS0xMjYzNjVhY2UwMDEiLCJpYXQiOjE0OTU0NDQyNjIsImV4cCI6MjEyNjE2NDI2MiwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.j0ZBPCECPkmYJFlaGyPa5tYueL-btKPoJD6XSCPd8YMol469E59EA_Yy5mvW9moHl93scGuOAHy_afpJ15XB0g',
  baseUrl: 'https://apiv2.prompto.com/v2',
  cloudinaryCloudName: 'prompto',
  cloudinaryImageBaseUrl: 'https://res.cloudinary.com/prompto/image/upload',
  homePageBgImageUrl:
    'https://promptogroup.com/wp-content/uploads/2023/01/Prompto_Erasmus_Gardens_INT02-2048x1152.jpg',
  termsOfService: 'https://promptogroup.com/terms-of-service/',
  privacyPolicy: 'https://promptogroup.com/privacy-policy-2/',
  cookiePolicy: 'https://promptogroup.com/cookie-policy/',
  linkedinUrl: 'https://www.linkedin.com/company/prompto-com',
  instagramUrl: 'https://www.instagram.com/prompto_newbuild/',
  aboutPrompto: 'https://promptogroup.com/about/',
  contactUs: 'https://promptogroup.com/contact-us/',
  leadGenerationUrl: 'https://promptogroup.com/lead-generation/',
  marketplaceUrl: 'https://prompto.com',
  facebookAccessToken:
    'EAAHA4u8XK4kBOxxc14kDrHkoZAU1tvdVmb7GVvTA1p4ZCGu7pRYUyxuPhiLbM1iwQUOVSdZCmbGkKUFrZCyR31NG8y6UUeyN0EpCCSyZCPZCzZAE6OPMosraxGJ5QlDQiLfkOsUZB4K0gLhwXZBq9yRUjlKyYNUJpNZCiZBjjCTAiqEI6Lr0d630kO2WaTpKXPE7OAt0QZDZD',
  firebaseApiKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',
  firebaseAuthDomain: 'vrtual-media-api-v2.firebaseapp.com',
  firebaseProjectId: 'vrtual-media-api-v2',
  firebaseStorageBucket: 'vrtual-media-api-v2.appspot.com',
  firebaseMessagingSenderId: '777643864247',
  firebaseAppId: '1:777643864247:web:12aa34c8e72c5b36b65d32',
  firebaseMeasurementId: 'G-C3EJLVG9RK'
};

export default env;

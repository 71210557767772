import { useEffect, useState } from 'react';

// Component
import PromptoLogo from 'src/resources/images/prompto-logo.png';
import PromptoLogoWhite from 'src/resources/images/prompto-logo-white.png';
import PromptoLogoIcon from 'src/resources/images/prompto-logo-icon.png';
import PromptoLogoIconWhite from 'src/resources/images/prompto-logo-icon-white.png';
import LanguageSwitcher from 'src/pages/landingPage/LanguageSwitcher';
import ContactUsButton from 'src/components/ContactUsButton';
import ProjectSearch from 'src/components/projectSearch/ProjectSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainMenuMobile from 'src/components/MainMobileMenu';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Helpers
import localizer, { getValueFromLocalized } from 'src/localization/localizer';
import { useDataState } from 'src/store/DataStore';
import { useAuthState } from 'src/store/AuthStore';
import { useAppState } from 'src/store/AppStore';
import { TrackGetInTouchBasicParams } from '@/libs/prompto-api/src';
import { isMobileOnly } from 'react-device-detect';
import { getCurrentProject } from 'src/helpers/vmProjectSection';
import { Tracking } from '@prompto-api';
import env from 'src/environment';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { motion } from 'framer-motion';
import { capitalize } from 'src/helpers/utils';
import ProfilePicture from './ProfilePicture';

const ContentWrapper = styled.div<StyledProps<{ isHomePage?: boolean }>>`
  width: 100%;
  max-height: 72px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${({ isHomePage, theme }) => (isHomePage ? 'none' : theme.white)};
  z-index: 3007;
  ${forBiggerThan.laptop`
  height: 96px;
  padding: 8px 32px;
  `}
`;

const MenuItemsWrapper = styled.div`
  display: none;
  width: 0;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  gap: 40px;

  ${forBiggerThan.tablet`
    display: flex;
    width: auto;
    overflow: visible;
  `}
`;

const MenuItem = styled.div<StyledProps<{ isHomePage?: boolean }>>`
  font-size: 1rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ isHomePage, theme }) =>
    isHomePage ? theme.white : theme.gray80};
  user-select: none;
  cursor: pointer;
`;

const MainMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  cursor: pointer;
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  position: relative;
  z-index: 3004; // for 1 bigger than for mobile menu

  ${forBiggerThan.tablet`
    display: none;
  `}
`;

const MainMenuIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const StyledSwitcherWrapper = styled(LanguageSwitcher)`
  display: none;

  ${forBiggerThan.tablet`
    display: block;
  `}
`;

const Wrapper = styled.div<StyledProps<{ isSticky: boolean }>>`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'absolute')};
  z-index: 3007;
  width: 100%;
  max-height: 72px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
  top: 0;

  ${forBiggerThan.laptop`
    height: 96px;
    padding: 8px 0;
  `}
`;

const AnimatedWrapper = styled(motion.div)<
  StyledProps<{ isFixed: boolean; isSticky: boolean; isHomePage?: boolean }>
>`
  position: ${({ isFixed, isSticky }) =>
    isFixed ? 'fixed' : isSticky ? 'sticky' : 'absolute'};
  width: 100%;
  height: 72px;
  max-height: 72px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${({ isHomePage, theme }) => (isHomePage ? 'none' : theme.white)};
  top: 0;

  ${forBiggerThan.laptop`
    height: 96px;
    padding: 8px 0;
  `}
`;

const Logo = styled.img`
  height: 100%;
  max-width: 60px;
  object-fit: contain;
  object-position: center;

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 200px;
  position: relative;
  z-index: 3004; // for 1 bigger than for mobile menu

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;
  `}
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.gray300};
  align-self: center;
`;

const LogoDivider = styled.div`
  box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.black};
  height: 20px;
`;

const LogoText = styled.div<StyledProps<{ isHomePage?: boolean }>>`
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ isHomePage, theme }) => (isHomePage ? theme.white : theme.black)};
  text-align: center;
`;

const ButtonsWrapper = styled(motion.div)`
  display: flex;
  column-gap: 10px;
  flex-shrink: 0;
  align-items: center;

  ${forBiggerThan.laptop`
    column-gap: 16px;
  `}
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const LeadGenerationWrapper = styled.div`
  display: none;
  flex-shrink: 0;

  ${forBiggerThan.tablet`
    column-gap: 10px;
    display: flex;
  `}
  ${forBiggerThan.laptop`
    column-gap: 32px;
  `}
`;

const OpenLeadGenerationButton = styled.button`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  height: 48px;
  padding: 0 15px;
  letter-spacing: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 10px;

  ${forBiggerThan.laptop`
    padding: 0 25px;
  `}
`;

const LoginButton = styled.div<StyledProps<{ isHomePage?: boolean }>>`
  display: none;
  align-items: center;
  font-weight: bold;
  color: ${({ isHomePage, theme }) => (isHomePage ? theme.white : theme.black)};
  cursor: pointer;

  ${forBiggerThan.tablet`
    display:flex;
  `}
`;

const LoginIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 8px;
`;

const FavouriteButton = styled.div<StyledProps<{ isHomePage?: boolean }>>`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: solid 1px #e3e5e8;
  background-color: ${({ isHomePage, theme }) =>
    isHomePage ? 'none' : theme.white};
  color: ${({ isHomePage, theme }) => (isHomePage ? theme.white : theme.black)};
  cursor: pointer;
`;

const FavouriteIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
  height: 18px !important;
`;

const FavouriteCount = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.gold};
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
`;

const ProfilePicWrapper = styled.div`
  display: none;

  ${forBiggerThan.tablet`
    display:flex;
  `}
`;

export interface HeaderProps {
  onLanguageSwitched: (language: string) => void;
  isSticky?: boolean;
  isFixedByForce?: boolean;
  onToggleMenu?: (flag: boolean) => void;
  className?: string;
}

const Header = ({
  onLanguageSwitched,
  isSticky = false,
  isFixedByForce = false,
  onToggleMenu,
  className
}: HeaderProps) => {
  const navigate = useNavigate();

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isFixed, setIsFixed] = useState(isFixedByForce);
  const [navbarState, setNavbarState] = useState('visible');

  const [isHomePage, setIsHomePage] = useState(false);

  const [trackingData, setTrackingData] =
    useState<TrackGetInTouchBasicParams>();

  const { DataState, DataStateDispatch } = useDataState();
  const { scrollToTop, projects, currentVaultId, currentProjectId } = DataState;

  const { AuthState } = useAuthState();
  const {
    user,
    favouriteProjects,
    tempFavouriteProjects,
    favouriteUnits,
    tempFavouriteUnits
  } = AuthState;

  const vaultId = currentVaultId;
  const projectId = currentProjectId;

  // App state
  const { AppState } = useAppState();
  const { sessionId, visitorId, marketplaceId } = AppState;

  const isOnProjectPage =
    window.location.pathname.includes('project') &&
    !window.location.pathname.includes('projects');
  // Pathname options are:
  //  a. /vault/xxxxxxxxx/project/yyyyyyyyy - project page
  //  b. /vault/xxxxxxxxx/project/yyyyyyyyy/unit/zzzzzzz - unit page

  // Being split by '/' we receive an array:
  // [0,  1  ,   2     ,   3   ,    4    ,  5  ,  6   ]
  //   /vault/xxxxxxxxx/project/yyyyyyyyy/unit/zzzzzzz
  const [, , , , , , unitId] = window.location.pathname.split('/');

  const thisProject = getCurrentProject(projects, projectId || ''); // PROJECT TITLE
  const projectTitle = getValueFromLocalized(
    thisProject?.localizedTitle,
    thisProject?.title ?? ''
  );

  const { pathname } = useLocation();

  // Logic for setting page title + canonical link
  useEffect(() => {
    // Update title and header of page for indexing
    if (pathname.includes(`nieuwbouw`) || pathname.includes(`developer`)) {
      const paths = location.pathname.split('/');

      if (paths[1] && paths[2]) {
        document.title = `Nieuwbouw - ${capitalize(paths[2])}`;
      }

      const linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'canonical');
      linkTag.href = window.location.href;
      document.head.appendChild(linkTag);
    } else if (projectTitle && !pathname.includes('projects')) {
      document.title = `${projectTitle} - Nieuwbouw`;
    } else {
      document.title = 'Prompto';
    }
  }, [projectTitle, pathname]);

  useEffect(() => {
    setIsHomePage(pathname === '/');
  }, [pathname]);

  useEffect(() => {
    if (onToggleMenu) {
      onToggleMenu(isMenuOpened);
    }
  }, [isMenuOpened, onToggleMenu]);

  useEffect(() => {
    if (!projectId || !vaultId) return;

    const trackingData: TrackGetInTouchBasicParams = {
      visitorId,
      sessionId,
      projectObjectId: projectId,
      vaultObjectId: vaultId,
      marketplaceObjectId: marketplaceId
    };
    setTrackingData(trackingData);
  }, [marketplaceId, sessionId, visitorId, window.location.pathname]);

  useEffect(() => {
    if (scrollToTop) {
      const scrollIntoViewOptions = {
        top: -10000,
        behavior: 'smooth' as ScrollBehavior
      };
      window.scrollBy(scrollIntoViewOptions);
      DataStateDispatch({
        type: 'setScrollToTop',
        payload: false
      });
    }
  }, [scrollToTop]);

  // Save the current scroll position before navigating
  useEffect(() => {
    if (isFixedByForce) return;
    const handleScroll = () => {
      // by default heading wrapper has absolute positioning
      if (!isMobileOnly) {
        // by this flag it changed to 'fixed'
        // then it possible to do animation on it
        setIsFixed(window.scrollY > 100);
        if (window.scrollY > 72 && window.scrollY < 100) {
          // this value needed to be change to be able to trigger animation on wrapper
          setNavbarState('hidden');
        } else {
          setNavbarState('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixedByForce]);

  const onLogoClick = () => {
    if (marketplaceId && sessionId && visitorId) {
      const trackingData = {
        actionName: 'trackMarketplaceNavigation',
        targetPage: 'home',
        marketplaceId,
        visitorId,
        sessionId
      };
      Tracking.trackMarketplaceAction(trackingData).catch(() => {});
    }
    navigate(`/${window.location.search}`);
  };

  const whiteVersion = isHomePage && !isFixed && !isMobileOnly;

  let logoUrl = PromptoLogo;
  if (isMobileOnly) {
    logoUrl = PromptoLogoIcon;
  } else if (whiteVersion) {
    logoUrl = PromptoLogoWhite;
  }

  let userButton = (
    <LoginButton
      isHomePage={whiteVersion}
      onClick={() => {
        navigate(`/login${window.location.search}`);
      }}
    >
      <LoginIcon icon={['fas', 'sign-in']} size="sm" /> Login
    </LoginButton>
  );

  if (user && user !== undefined) {
    userButton = (
      <ProfilePicWrapper>
        <ProfilePicture
          isWhite={whiteVersion}
          onClick={() => {
            navigate(`/account${window.location.search}`);
          }}
          enableDropdown={true}
        />
      </ProfilePicWrapper>
    );
  }

  const totalFavouriteProjects = [
    ...favouriteProjects,
    ...tempFavouriteProjects
  ];
  const totalFavouriteUnits = [...favouriteUnits, ...tempFavouriteUnits];

  const totalCount =
    totalFavouriteProjects?.length + totalFavouriteUnits?.length;

  const mainContent = (
    <ContentWrapper isHomePage={whiteVersion}>
      <LogoWrapper>
        <LogoContainer onClick={onLogoClick}>
          <Logo src={logoUrl} alt="Prompto logo" />
        </LogoContainer>
        {!isMobileOnly && (
          <>
            <LogoDivider />
            <LogoText isHomePage={whiteVersion}>
              {localizer.header.slogan}
            </LogoText>
          </>
        )}
      </LogoWrapper>

      <MenuItemsWrapper>
        <MenuItem
          onClick={() => {
            ProjectsFiltersDispatch({
              type: 'setVaultFilter',
              payload: null
            });

            navigate(`/projects` + window.location.search);
          }}
          isHomePage={whiteVersion}
        >
          {localizer.header.menu.offer}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/developers` + window.location.search);
          }}
          isHomePage={whiteVersion}
        >
          {localizer.header.menu.developers}
        </MenuItem>
        <MenuItem
          onClick={() => window.open(env().leadGenerationUrl, '_blank')}
          isHomePage={whiteVersion}
        >
          {localizer.header.menu.advertiseHere}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`${env().contactUs}?utm_source=marketplace`, '_blank');
          }}
          isHomePage={whiteVersion}
        >
          {localizer.header.menu.contactUs}
        </MenuItem>
      </MenuItemsWrapper>
      <ProjectSearch
        mobileView={false}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        isHomePage={whiteVersion}
      />

      <ButtonsWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {
          // for mobile - button in bottom right corner
          // for desktop - it just hidden
          isOnProjectPage && (
            <ContactUsButton
              location="navigationBarProjectPage"
              trackingData={trackingData as TrackGetInTouchBasicParams}
            />
          )
        }
        <StyledSwitcherWrapper onLanguageSwitched={onLanguageSwitched} />

        <Divider />
        {userButton}
        <FavouriteButton
          isHomePage={whiteVersion}
          onClick={() => {
            navigate('/account?tab=favourites');
          }}
        >
          <FavouriteIcon
            icon={[whiteVersion ? 'fas' : 'far', 'heart']}
            size="sm"
          />
          {totalCount > 0 && <FavouriteCount>{totalCount}</FavouriteCount>}
        </FavouriteButton>
        <MainMenuButton onClick={() => setIsMenuOpened(!isMenuOpened)}>
          <MainMenuIcon
            icon={['far', isMenuOpened ? 'times' : 'bars']}
            size="1x"
          />
        </MainMenuButton>
        <MainMenuMobile
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
          onLanguageSwitched={onLanguageSwitched}
        />
      </ButtonsWrapper>
    </ContentWrapper>
  );

  return isMobileOnly ? (
    <Wrapper isSticky={isSticky}>{mainContent}</Wrapper>
  ) : (
    <AnimatedWrapper
      initial={{ opacity: 0, height: 0 }}
      animate={
        navbarState === 'visible'
          ? { opacity: 1, height: '72px', zIndex: 3007 }
          : { opacity: 0, height: 0, zIndex: 0 }
      }
      exit={{ height: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      isFixed={isFixed}
      isSticky={isSticky}
      isHomePage={whiteVersion}
    >
      {mainContent}
    </AnimatedWrapper>
  );
};

export default Header;

import { useEffect, useState } from 'react';

// Helpers
import { fetchSettingsFromURL } from 'src/helpers/utils';
import localizer, { setLanguage } from 'src/localization/localizer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Button from 'src/components/button/Button';

// Styling
import styled, { keyframes } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  margin-top: 70px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 2rem;
  text-align: center;
`;

const Email = styled.div`
  font-size: 1.5rem;
  margin-top: 15px;
  font-style: italic;
`;

const Description = styled.div`
  font-size: 1rem;
  margin-top: 50px;
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)`
  height: 60px;
  min-width: 250px;
  grid-area: button;
  justify-self: center;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.gold};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-top: 25px;

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const LeadOptInPage = () => {
  const queryParams = fetchSettingsFromURL();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>();
  const [optedOut, setOptedOut] = useState<boolean>(false);

  useEffect(() => {
    if (queryParams.email) {
      setEmail(queryParams.email as string);
    }
    if (queryParams.language) {
      setLanguage(queryParams.language as string);
    }
  }, [queryParams]);

  useEffect(() => {
    if (email && !optedOut) {
      setOptedOut(true);

      axios.post(
        'https://hooks.zapier.com/hooks/catch/1516233/2yhs59r/',
        JSON.stringify({ email })
      );
    }
  }, [email]);

  return (
    <Wrapper>
      <Title>{localizer.optOutPage.title}</Title>
      <Email>{email}</Email>
      <Description>{localizer.optOutPage.description}</Description>
      <StyledButton
        onClick={() => {
          navigate(`/projects` + window.location.search);
        }}
      >
        {localizer.homePage.viewProjects}
      </StyledButton>
    </Wrapper>
  );
};

export default LeadOptInPage;

import { useCallback, useEffect, useRef, useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-common-types';

// Components
import AddFiltersButton from './components/AddFiltersButton';
import FilterItem from './components/FilterItem';

// Helpers
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

import filtersConfig from 'src/configs/ProjectsFilters';
import ReactResizeDetector from 'react-resize-detector';
import localizer, { getCurrentLanguage } from 'src/localization/localizer';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import useUserSearchPreferences from 'src/hooks/use-user-search-preferences';

const Wrapper = styled(motion.div)<StyledProps<{ fixed?: boolean }>>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  z-index: 3006;
  top: ${isMobileOnly ? 0 : 72}px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.beigeBg10};
  padding: 8px 16px;
  box-sizing: border-box;

  ${forBiggerThan.laptop`
    padding: 8px 32px;
    `}
`;

const SelectedFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${filtersConfig.uiSettings.filtersContainerFlexGap}px;
  flex-wrap: wrap;
`;

const AdditionalElementsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ClearAllFilters = styled(motion.button)`
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0 8px;
  color: ${({ theme }) => theme.black};
  cursor pointer;
  border: none;
  background: none;
  width: 100px;
  flex-shrink: 0;

  ${forBiggerThan.mobile`
  width: auto;
  `}
`;

const StyledImage = styled.img`
  height: 50px;
  object-fit: contain;
  margin-right: 50px;
`;

enum ProjectsFiltersKeys {
  location = 'location',
  price = 'price',
  surface = 'surface',
  numberOfBedrooms = 'numberOfBedrooms',
  numberOfBathrooms = 'numberOfBathrooms'
}

export type ProjectsFilterKeyType = keyof typeof ProjectsFiltersKeys;

export interface IProjectFilter {
  key: ProjectsFilterKeyType;
  label: string;
  icon: IconName;
  focused?: boolean;
}

export interface ISelectedProjectFilter extends IProjectFilter {
  selected: boolean;
  focused: boolean;
}

const ProjectsFilters = ({
  renderAdditionalUI: RenderAdditionalUI,
  mapLoaded
}: any) => {
  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const { availableFilters, selectedFilters, vaultFilter } =
    ProjectsFiltersState;

  // Filters UI related
  const [maxFiltersNumberToDisplayInBar, setMaxFiltersNumberToDisplayInBar] =
    useState(0);
  const [filtersAreFixed, setFiltersAreFixed] = useState(false);
  const [lang, setLang] = useState<any>();

  useUserSearchPreferences();

  // Normally the AddFilters button displays a list of available filter options,
  // but if can't display more filter items in the filter bar
  // the AddFilters button acts as a filter manager
  // Available modes: 'list' | 'filterManager'
  const [addFiltersButtonMode, setAddFiltersButtonMode] = useState<
    'list' | 'filtersManager'
  >('list');

  const currentLang = getCurrentLanguage();

  useEffect(() => {
    setLang(currentLang);
  }, [currentLang]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (isMobileOnly ? 120 : 200)) {
        setFiltersAreFixed(true);
      } else {
        setFiltersAreFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const additionalElementsRef = useRef<any>(null);
  const addFiltersRef = useRef<HTMLDivElement>(null);
  const clearAllRef = useRef<any>(null);

  const calculateFilterBarCapacity = () => {
    if (
      wrapperRef.current &&
      additionalElementsRef.current &&
      addFiltersRef.current
    ) {
      const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
      const additionalElementsWidth =
        additionalElementsRef.current.getBoundingClientRect().width;
      const addFiltersWidth =
        addFiltersRef.current.getBoundingClientRect().width;

      // 64 - wrapper horizontal paddings 32 x 2
      let availableSpace =
        wrapperWidth - additionalElementsWidth - addFiltersWidth - 64;

      if (clearAllRef.current) {
        const clearAllWidth = clearAllRef.current.getBoundingClientRect().width;
        availableSpace -= clearAllWidth;
      }

      const numberOfFilterItemsCanBeFit = Math.max(
        Math.floor(
          (availableSpace + filtersConfig.uiSettings.filtersContainerFlexGap) /
            (filtersConfig.uiSettings.filterButtonWidth +
              filtersConfig.uiSettings.filtersContainerFlexGap)
        ),
        0
      );

      setMaxFiltersNumberToDisplayInBar(numberOfFilterItemsCanBeFit);
    }
  };

  // Calculate how many filter items can fit into filter bar
  // The rest should be displayed in the AddFilters button's dropdown
  useEffect(() => {
    calculateFilterBarCapacity();
  }, [calculateFilterBarCapacity]);

  // Set AddFilters button mode
  useEffect(() => {
    if (
      selectedFilters.filter(
        (filter: ISelectedProjectFilter) => filter.selected
      ).length >
      maxFiltersNumberToDisplayInBar - 1
    ) {
      setAddFiltersButtonMode('filtersManager');
    } else {
      setAddFiltersButtonMode('list');
    }
  }, [maxFiltersNumberToDisplayInBar, selectedFilters]);

  // TODO: get filter options and limits from backend
  // useEffect(() => {}, []);

  const onFilterSelected = useCallback(
    (filterKey: ProjectsFilterKeyType) => {
      console.log('on select filter');
      console.log(filterKey);
      ProjectsFiltersDispatch({ type: 'onFilterSelected', payload: filterKey });
    },
    [ProjectsFiltersDispatch]
  );

  const onFilterDeselected = useCallback(
    (filterKey: ProjectsFilterKeyType) => {
      ProjectsFiltersDispatch({
        type: 'onFilterDeselected',
        payload: filterKey
      });
    },
    [ProjectsFiltersDispatch]
  );

  const clearAllFilters = useCallback(() => {
    ProjectsFiltersDispatch({ type: 'clearAllFilters' });
  }, [ProjectsFiltersDispatch]);

  const onApplyFilters = useCallback(() => {
    ProjectsFiltersDispatch({ type: 'onApplyFilters' });
  }, [ProjectsFiltersDispatch]);

  const visibleFilterItems = selectedFilters
    .filter((filter: ISelectedProjectFilter) => filter.selected)
    .slice(0, maxFiltersNumberToDisplayInBar);

  const logoToShow = buildAssetURIWithOptions('h=250', vaultFilter?.logo ?? '');

  const filters = (filtersAreFixed = false) => (
    <Wrapper
      ref={wrapperRef}
      fixed={filtersAreFixed}
      initial={{ opacity: 0, y: filtersAreFixed ? -80 : 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: filtersAreFixed ? -80 : 0 }}
      transition={{ duration: 0.12 }}
    >
      <SelectedFilters>
        {mapLoaded
          ? visibleFilterItems.map((filter: ISelectedProjectFilter) => {
              if (filter.selected) {
                return (
                  <FilterItem
                    key={filter.key}
                    filter={filter}
                    removeFilter={onFilterDeselected}
                    lang={lang}
                  />
                );
              }
            })
          : null}
      </SelectedFilters>

      <AddFiltersButton
        ref={addFiltersRef}
        mode={addFiltersButtonMode}
        isFilterEmpty={selectedFilters.every(
          (filter: ISelectedProjectFilter) => filter.selected === false
        )}
        availableFilters={availableFilters}
        selectedFilters={selectedFilters
          .filter((filter: ISelectedProjectFilter) => filter.selected)
          .slice(maxFiltersNumberToDisplayInBar, selectedFilters.length)}
        onFilterSelected={onFilterSelected}
        removeFilter={onFilterDeselected}
        onClearAll={clearAllFilters}
        onApplyFilters={onApplyFilters}
        alignToLeft={visibleFilterItems.length < 1}
      />

      {vaultFilter && !isMobileOnly && <StyledImage src={logoToShow} />}

      <AnimatePresence>
        {(selectedFilters.filter(
          (filter: ISelectedProjectFilter) => filter.selected
        ).length > 0 ||
          vaultFilter) &&
          !isMobileOnly && (
            <ClearAllFilters
              ref={clearAllRef}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              onClick={() =>
                ProjectsFiltersDispatch({ type: 'clearAllFilters' })
              }
            >
              {localizer.unitListComponent.clearAll}
            </ClearAllFilters>
          )}
      </AnimatePresence>

      {/* ADDITIONAL ELEMENTS NOT RELATED TO FILTER ITSELF */}
      {RenderAdditionalUI && (
        <AdditionalElementsWrapper ref={additionalElementsRef}>
          <RenderAdditionalUI />
        </AdditionalElementsWrapper>
      )}

      <ReactResizeDetector
        handleHeight
        handleWidth
        skipOnMount
        onResize={() => calculateFilterBarCapacity()}
      />
    </Wrapper>
  );

  return (
    <>
      {filters()}
      {filtersAreFixed && filters(true)}
    </>
  );
};

export default ProjectsFilters;

// Import the envs to pick from
import envDev from 'src/config/development/env';
import envProd from 'src/config/production/env';
import qs from 'query-string';

type EnvironmentType = 'production' | 'development';

interface IEnvironment {
  homePageBgImageUrl: string | undefined;
  env: string;
  baseImageUrl: string;
  googleMapsApiKey: string;
  apiToken360: string;
  baseUrl: string;
  apiToken: string;
  base360Url: string;
  base360ImageUrl: string;
  googleStorageBucketId: string;
  cloudinaryVideoBaseUrl: string;
  cloudinaryCloudName: string;
  cloudinaryImageBaseUrl: string;
  applicationUrl?: string;
  termsOfService: string;
  privacyPolicy: string;
  cookiePolicy: string;
  linkedinUrl: string;
  instagramUrl: string;
  aboutPrompto: string;
  contactUs: string;
  leadGenerationUrl: string;
  marketplaceUrl: string;
  facebookAccessToken: string;
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
  firebaseMessagingSenderId: string;
  firebaseAppId: string;
  firebaseMeasurementId: string;
}

const environment = (envQueryParam?: EnvironmentType): IEnvironment => {
  const { env } = qs.parse(window.location.search.substring(1));
  if (envQueryParam || env) {
    return envQueryParam === 'production' || env === 'production'
      ? envProd
      : envDev;
  }

  if (import.meta.env.VITE_APP_PROD === 'true') {
    return envProd;
  }

  return envDev;
};

export default environment;

import { useEffect, useState, useCallback } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';

// Helpers
import localizer, {
  getCurrentLanguage,
  getValueFromLocalized,
  setLanguage
} from 'src/localization/localizer';
import { isMobileOnly } from 'react-device-detect';
import { breakpoints, forBiggerThan } from 'src/helpers/ui';
import { useAuthState } from 'src/store/AuthStore';
import {
  IContentItem,
  ContentCollection,
  IUnit,
  Marketplace,
  UnitState,
  NavigationCollection,
  Tracking,
  TrackMarketplaceProjectVisitParams,
  TrackGetInTouchBasicParams,
  ProjectFolderConfiguration,
  GeneralFieldType,
  ShareItem
} from '@prompto-api';
import numeral from 'numeral';
import currencySymbolMap from 'currency-symbol-map';
import { useDataState } from 'src/store/DataStore';
import { useAppState } from 'src/store/AppStore';
import { useContactUsState } from 'src/store/ContactUsStore';
import {
  fetchSettingsFromURL,
  orderContentCollection
} from 'src/helpers/utils';
import { checkUnitFieldsVisibility } from 'src/helpers/vmUnit';
import qs from 'query-string';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdditionalInformationBlock from 'src/components/additionalInformationBlock/AdditionalInformationBlock';
import GetInTouchCard from 'src/components/getInTouchForm/GetInTouchCard';
import InformationBadge, {
  BadgeColor
} from 'src/components/informationBadge/InformationBadge';
import GetInTouchFormModal from 'src/components/getInTouchForm/GetInTouchFormModal';
import Resources from 'src/components/resources/Resources';
import MapModal from './components/MapModal';
import { RichTextRenderer } from '@prompto-ui';
import UnitList from 'src/components/unitList/UnitList';
import ProjectTour from 'src/components/projectTour/ProjectTour';
import UnitDetailPage, {
  TopBarWrapper
} from '../unitDetailPage/UnitDetailPage';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { buildAddress, getCurrentProject } from 'src/helpers/vmProjectSection';
import Button from 'src/components/button/Button';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import ShareButton from 'src/components/ShareButton';
import { sortUnits } from 'src/components/unitList/unitListHelpers';
import CreateAccountBlock from '../landingPage/CreateAccountBlock';

const PageWrapper = styled.div<StyledProps<{hideMenu?: boolean}>>`
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.white};
  z-index: 1000;
  margin-top: ${({hideMenu}) => hideMenu ? '0' : '72px'};
`;

const MainProjectInfoSection = styled.div`
  display: grid;
  margin: 8px 16px 0px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas:
    'Title'
    'Badges'
    'ProjectInfo'
    'Fomo'
    'Form'
    'ProjectDescription';

  @media (max-width: ${breakpoints.tablet}) {
    justify-items: center;
  }

  ${forBiggerThan.tablet`
    margin: 24px 50px 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr 1fr;

    grid-template-areas:
      'Title Form'
      'Badges Form'
      'ProjectInfo Form'
      'ProjectDescription ProjectDescription';
    `}

  ${forBiggerThan.laptop`
    margin: 24px 50px 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr 1fr;

    grid-template-areas:
      'Title Form'
      'Badges Form'
      'ProjectInfo Form'
      'ProjectDescription ProjectDescription';
    `}

  ${forBiggerThan.desktop`
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto auto auto;
    margin: 64px 150px 0px;
    margin-top: 48px;

    grid-template-areas:
      'Title Badges Form'
      'ProjectInfo ProjectInfo Form'
      'ProjectDescription ProjectDescription Form';
    `}
`;

const ProjectTitleAndAddress = styled.div`
  grid-area: Title;
  display: flex;
  flex-flow: column;
`;

const ProjectTitle = styled.div`
  width: 100%;
  font-size: 1.875rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  margin-right: 32px;
  margin-top: 16px;
  margin-bottom: 12px;
  text-align: center;

  ${forBiggerThan.tablet`
  margin-top: 0;
  font-size: 2.25rem;
  text-align: inherit;
    `}
`;

const ProjectAddress = styled.div`
  display: flex;
  flex-flox: row;
  align-items: center;
  color: ${({ theme }) => theme.gold60};
  cursor: pointer;
`;

const AddressName = styled.div`
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  font-size: 0.875rem;

  ${forBiggerThan.tablet`
  font-size: 1.125rem;
    `}
`;

const PriceBadgeWrapper = styled.div`
  grid-area: Badges;
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  padding-left: 0;
  padding-top: 24px;
  margin-top: 16px;

  ${forBiggerThan.desktop`
      justify-content: center;
      paddin-top: 0;
      padding-left: 8px;
      margin-top: 8px;
  `}
`;

const PriceBadge = styled(InformationBadge)``;

const VATBadge = styled(InformationBadge)`
  margin-left: auto;
  margin-right: 16px;
`;

const ProjectAdditionalInformation = styled(AdditionalInformationBlock)`
  grid-area: ProjectInfo;
  margin-top: 32px;
  margin-bottom: 32px;

  ${forBiggerThan.tablet`
  margin-top: 48px;
    `}
`;

const ProjectDescription = styled.div`
  grid-area: ProjectDescription;
  margin-bottom: 32px;
  max-width: 95vw;
`;

const ProjectDescriptionLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 24px;
  margin-top: 32px;

  ${forBiggerThan.tablet`
  margin-top: 0;
    `}
`;

const ProjectDescriptionContent = styled.div``;

const GetInTouchZone = styled.div`
  grid-area: Form;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0;
  margin-left: auto;
  margin-right: auto;

  ${forBiggerThan.tablet`
  margin-left: 50px !important;
  `}

  ${forBiggerThan.desktop`
  margin-left: 184px;
  `}
`;

const GetInTouchCTA = styled(GetInTouchCard)``;

const ResourcesWrapper = styled.div`
  width: 100%;
`;

const UnitListWrapper = styled.div`
  width: 100%;
  margin-top: 96px;
`;

const ExposedFormSection = styled.div`
  background-color: ${({ theme }) => theme.beigeBg10};
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.gray50};
`;

const ScrollToUnitsButton = styled.div`
  padding: 15px 59px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.beigeBg10};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  max-width: 272px;
  margin-top: 16px;
  user-select: none;
  cursor: pointer;
  text-align: center;

  ${forBiggerThan.tablet`
  margin-top: 0px;
  margin-bottom: 16px;
  `}
`;

const ScrollToUnitsIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  margin-right: 8.5px;
`;

const AddressIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const MapButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const MapButton = styled(Button)`
  width: 30px;
  height: 30px;
  min-width: 30px;
`;

const StyledShareButton = styled(ShareButton)`
  margin-left: 12px;
`;

const RightPartSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
`;

const RequestPriceButton = styled.div`
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.beigeBg20};
  padding: 8px 16px;
  color: ${({ theme }) => theme.black};
  font-weight: normal;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 12px;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  user-select: none;

  ${forBiggerThan.tablet`
     margin-left: auto;
  margin-top: 0px;
  font-size: 1.25rem;
  `}
`;

const RequestPriceIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  margin-left: 8px;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const TooltipMessage = styled.div`
  font-size: 0.75rem;
`;

const GoBackButton = styled.div<
  StyledProps<{
    isFixed: boolean;
    isMobile: boolean;
  }>
>`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ isFixed, isMobile }) =>
    isFixed
      ? css`
          position: fixed;
          z-index: 11;
          ${isMobile ? 'top: 13px;' : ''}
        `
      : ''}
`;

const BackIconWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.beigeBg20};
  margin-right: 16px;
  height: 40px;
  width: 40px;
  display: flex;
  pointer-events: none;
  position: relative;
  span {
    margin: auto;
    font-size: 1.5rem;
  }
`;

const BackText = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  display: none;

  ${forBiggerThan.tablet`
  display: block;    `}
`;

const FavouriteButton = styled.div`
  display: flex;
  height: 48px;
  min-width: 48px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  background: ${({ theme }) => theme.beigeBg20};
  cursor: pointer;
  z-index: 5;
`;

const FavouriteIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;
  color: black;
`;

const FOMO_INTERVAL = 10 * 1000;

const ProjectDetailPage = () => {
  const [isMapOpened, setIsMapOpened] = useState<boolean>(false);
  const [fetchingContent, setFetchingContent] = useState(true);
  const [companyLogoUri, setCompanyLogoUri] = useState<string>('');
  const [isFixedBackButton, setIsFixed] = useState(false);

  // project tour related
  // this collection contains only published items
  const [projectContentCollection, setProjectContentCollection] =
    useState(null);
  // this collection contains all content items
  const [fullProjectContentCollection, setFullProjectContentCollection] =
    useState(null);
  const [projectExploreNavItem, setProjectExploreNavItem] = useState(null);

  const { vaultObjectId, projectObjectId, code } = useParams<string>();

  const [vaultId, setVaultId] = useState<string>(vaultObjectId ?? '');
  const [projectId, setProjectId] = useState<string>(projectObjectId ?? '');

  // Get In Touch state
  const { ContactUsState, ContactUsStateDispatch } = useContactUsState();
  const { showContactUsForm, formLocation, unitObjectId } = ContactUsState;

  const {hideMenu, disableAccount} = fetchSettingsFromURL();

  // tracking
  const [canTrackProjectVisit, setCanTrackProjectVisit] = useState(false);
  const [basicTrackingData, setBasicTrackingData] =
    useState<TrackGetInTouchBasicParams>();
  const [projectVisitedActionId, setProjectVisitedActionId] = useState();
  const [
    projectBeingVisitedCheckInterval,
    setProjectBeingVisitedCheckInterval
  ] = useState<any>();

  // Data state
  const { DataState, DataStateDispatch } = useDataState();
  const { projects } = DataState;

  // App state
  const { AppState } = useAppState();
  const { sessionId, visitorId, marketplaceId } = AppState;

  const { AuthState, AuthStateDispatch } = useAuthState();
  const { favouriteProjects } = AuthState;

  const thisProject = getCurrentProject(projects, projectId);

  const contentItemList =
    thisProject?.vmContentCollection?.vmContentItemList?.filter(
      (contentItem) => contentItem?.contentItemType !== 'document'
    ) ?? [];

  const unitList = thisProject?.unitList ?? [];
  const previewContent = thisProject?.previewContent ?? [];

  /**
   * START of logic to hide prices based on url param and to show contact us form
   */
  const requestUnitPrice = () => {
    ContactUsStateDispatch({
      type: 'setShowContactUsForm',
      payload: {
        showContactUsForm: true,
        location: 'projectPagePrice'
      }
    });
  };

  const requestUnitPriceButton = (
    <RequestPriceButton
      onClick={(e) => {
        e.stopPropagation();
        requestUnitPrice();
      }}
    >
      {localizer.unitList.priceOnRequest}
      <RequestPriceIcon icon={['far', 'arrow-right']} size="1x" />
    </RequestPriceButton>
  );
  /**
   * END of logic to hide prices
   */

  const navigate = useNavigate();

  const getUnitContentAndLinkedCollection = (unit: IUnit) => {
    // Check normal content collection
    if (unit.vmContentCollection) {
      ContentCollection.get(unit.vmContentCollection.objectId).then((res) => {
        if (res?.data) {
          const { vmContentCollection } = res.data;

          const listWithOnlyPublishedContent =
            vmContentCollection.vmContentItemList.filter(
              (item: IContentItem) => {
                return item.contentItemState === 'published';
              }
            );

          DataStateDispatch({
            type: 'storeContentCollection',
            payload: {
              ...vmContentCollection,
              vmContentItemList: listWithOnlyPublishedContent
            }
          });
        }
      });
    }

    // Check linked content collection
    if (unit.linkedContentCollection) {
      ContentCollection.get(unit.linkedContentCollection.objectId).then(
        (res) => {
          if (res?.data) {
            const { vmContentCollection } = res.data;
            const listWithOnlyPublishedContent =
              vmContentCollection.vmContentItemList.filter(
                (item: IContentItem) => {
                  return item.contentItemState === 'published';
                }
              );

            DataStateDispatch({
              type: 'storeContentCollection',
              payload: {
                ...vmContentCollection,
                vmContentItemList: listWithOnlyPublishedContent
              }
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (vaultObjectId && projectObjectId) {
      setVaultId(vaultObjectId);
      setProjectId(projectObjectId);
    }
  }, [vaultObjectId, projectObjectId]);

  // Logic for the /project/:code url that brings people to a specific project
  useEffect(() => {
    if (code) {
      ShareItem.getByCode(code)
        .then((result: any) => {
          if (result.data) {
            const { vaultObjectId, projectObjectId, queryParams } =
              result.data.shareItem;

            setVaultId(vaultObjectId);
            setProjectId(projectObjectId);

            const params = new URLSearchParams(queryParams);
            const paramsObject: any = {};
            // Iterate over the URLSearchParams entries and add them to the object
            for (const [key, value] of params.entries()) {
              paramsObject[key] = value;
            }

            if (paramsObject.language) {
              setLanguage(paramsObject.language);
            }
          }
        })
        .catch((e: any) => {
          navigate(`/projects${window.location.search}`);
        });
    }
  }, [code]);

  useEffect(() => {
    window.scrollBy({
      top: -10000,
      behavior: 'auto' as ScrollBehavior
    });
  }, []);

  useEffect(() => {
    if (projectId && vaultId) {
      DataStateDispatch({
        type: 'storeCurrentProjectId',
        payload: projectId
      });

      DataStateDispatch({
        type: 'storeCurrentVaultId',
        payload: vaultId
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (
      canTrackProjectVisit &&
      sessionId &&
      visitorId &&
      marketplaceId &&
      projectId &&
      vaultId
    ) {
      setCanTrackProjectVisit(false);

      const trackProjectVisitParams: TrackMarketplaceProjectVisitParams = {
        visitorId: visitorId,
        sessionId: sessionId,
        projectObjectId: projectId,
        vaultObjectId: vaultId,
        marketplaceId: marketplaceId
      };

      setBasicTrackingData({
        visitorId,
        sessionId,
        projectObjectId: projectId,
        vaultObjectId: vaultId,
        marketplaceObjectId: marketplaceId
      });

      Tracking.trackMarketplaceProjectVisit(trackProjectVisitParams)
        .then((result) => {
          setProjectVisitedActionId(result.data?.actionId);
        })
        .catch(() => {});
    }
  }, [
    sessionId,
    visitorId,
    marketplaceId,
    canTrackProjectVisit,
    projectId,
    vaultId
  ]);

  // Heart beat call to track users wacthing the project
  useEffect(() => {
    if (projectVisitedActionId) {
      setProjectBeingVisitedCheckInterval(
        setInterval(() => {
          Tracking.updateActionUpdateAtField({
            actionId: projectVisitedActionId
          }).catch(() =>
            console.warn('Could not update action updateAt field')
          );
        }, FOMO_INTERVAL)
      );
    }

    return () => {
      if (projectBeingVisitedCheckInterval)
        clearInterval(projectBeingVisitedCheckInterval);
    };
  }, [projectVisitedActionId]);

  useEffect(() => {
    if (vaultId && projectId && marketplaceId) {
      // 1. Get initial project info
      Marketplace.validateAndGetMarketplaceProjectLight(
        marketplaceId,
        vaultId,
        projectId
      )
        .then((result) => {
          if (result?.data) {
            const { vmProjectSection, marketplaceProjectSettings } =
              result.data;

            setCanTrackProjectVisit(true);

            DataStateDispatch({
              type: 'storeProjectData',
              payload: vmProjectSection
            });
            if (marketplaceProjectSettings) {
              // Calculate project settings
              const projectSettings = { ...marketplaceProjectSettings };
              if (!projectSettings.hidePrice) {
                const queryParams = fetchSettingsFromURL();
                const { hp } = queryParams;
                projectSettings.hidePrice = !!hp;
              }

              DataStateDispatch({
                type: 'storeProjectSettings',
                payload: {
                  objectId: vmProjectSection.objectId,
                  settings: projectSettings
                }
              });
            } else {
              const queryParams = fetchSettingsFromURL();
              const { hp } = queryParams;
              DataStateDispatch({
                type: 'storeProjectSettings',
                payload: {
                  objectId: vmProjectSection.objectId,
                  settings: {
                    hidePrice: !!hp
                  }
                }
              });
            }

            // 3. Get the units
            Marketplace.getMarketplaceProjectAndUnits(
              marketplaceId,
              vaultId,
              projectId
            ).then((result) => {
              if (result?.data) {
                const { vmUnitList, logoUri } = result.data;
                const filteredUnitList = vmUnitList.filter((x: IUnit) => {
                  return (
                    x.state !== UnitState.ARCHIVED &&
                    x.state !== UnitState.DRAFT
                  );
                });

                DataStateDispatch({
                  type: 'storeProjectUnits',
                  payload: {
                    projectObjectId: projectId,
                    unitList: filteredUnitList
                  }
                });

                setCompanyLogoUri(logoUri);

                const sortedUnits = sortUnits(filteredUnitList, 'title', true);
                for (let index = 0; index < sortedUnits.length; index += 2) {
                  setTimeout(() => {
                    if (sortedUnits[index]) {
                      getUnitContentAndLinkedCollection(sortedUnits[index]);
                    }
                    if (sortedUnits[index + 1]) {
                      getUnitContentAndLinkedCollection(sortedUnits[index + 1]);
                    }
                  }, (index / 2) * 2000);
                }
              }
            });

            // Now start getting entire collection
            ContentCollection.get(
              vmProjectSection.vmContentCollection.objectId,
              {
                includeContentItemList: true
              }
            ).then((result) => {
              const { vmContentCollection } = result.data;

              setFullProjectContentCollection(vmContentCollection);

              const listWithoutTurntables =
                vmContentCollection.vmContentItemList.filter(
                  (item: IContentItem) => item.contentItemType !== 'turntable'
                );
              const listWithOnlyPublishedContent = listWithoutTurntables.filter(
                (item: IContentItem) => {
                  return item.contentItemState === 'published';
                }
              );

              ProjectFolderConfiguration.get(projectId)
                .then((result) => {
                  const folderList =
                    result.data?.vmProjectFolderConfiguration?.folderList;

                  if (folderList) {
                    const orderedContentItems = orderContentCollection(
                      listWithOnlyPublishedContent,
                      folderList
                    );

                    setProjectContentCollection({
                      ...vmContentCollection,
                      vmContentItemList: orderedContentItems
                    });

                    DataStateDispatch({
                      type: 'storeProjectContent',
                      payload: {
                        projectObjectId: projectId,
                        vmContentCollection: {
                          ...vmContentCollection,
                          vmContentItemList: orderedContentItems
                        }
                      }
                    });

                    setFetchingContent(false);
                  }
                })
                .catch(() => {
                  setProjectContentCollection({
                    ...vmContentCollection,
                    vmContentItemList: listWithOnlyPublishedContent
                  });

                  DataStateDispatch({
                    type: 'storeProjectContent',
                    payload: {
                      projectObjectId: projectId,
                      vmContentCollection: {
                        ...vmContentCollection,
                        vmContentItemList: listWithOnlyPublishedContent
                      }
                    }
                  });

                  setFetchingContent(false);
                });
            });

            // Get project content collection and explore navigation item
            Promise.all([
              NavigationCollection.get(
                vmProjectSection.navigationCollection.objectId,
                true
              )
            ])
              .then(([navigationCollResult]) => {
                const exploreNavigationItem =
                  navigationCollResult?.data?.navigationCollection?.vmNavigationItemList?.find(
                    (navItem: any) => navItem.navigationItemType === 'explore'
                  );
                if (exploreNavigationItem) {
                  setProjectExploreNavItem(exploreNavigationItem);
                }
              })
              .catch();
          }
        })
        .catch((e) => {
          // Redirect to 404 if any error
          console.log(e.error);
          window.location.href = `${window.location.origin}/404`;
        });

      // 2. Get first couple resources
      Marketplace.getMarketplaceProjectResources(
        marketplaceId,
        vaultId,
        projectId,
        4
      )
        .then((res) => {
          if (res?.data) {
            const { vmContentItemList } = res.data;
            DataStateDispatch({
              type: 'storeProjectPreviewContent',
              payload: {
                projectObjectId: projectId,
                vmContentItemList
              }
            });
            setFetchingContent(false);
          }
        })
        .catch(() => {});
    }
  }, [vaultId, projectId, marketplaceId]);

  const returnToProjectPage = (scrollToImageTour?: boolean) => {
    document.body.style.overflow = 'auto';
    navigate(`/vault/${vaultId}/project/${projectId}` + window.location.search);

    if (scrollToImageTour) {
      setTimeout(() => {
        const scrollIntoViewOptions = {
          behavior: 'smooth' as ScrollBehavior
        };
        document
          ?.getElementById('unitList')
          ?.scrollIntoView(scrollIntoViewOptions);
      }, 500);
    }
  };

  const scrollToUnitsSection = () => {
    const targetElement = document.getElementById('unitTitle');
    const elementRect = targetElement?.getBoundingClientRect();

    if (elementRect) {
      // Calculate the scroll position with a slight offset (e.g., 20 pixels)
      const offset = 75;
      const scrollTop = elementRect.top + window.scrollY - offset;

      // Scroll to the calculated position
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  };

  // PROJECT TITLE
  const projectTitle = getValueFromLocalized(
    thisProject?.localizedTitle,
    thisProject?.title ?? ''
  );

  // PROJECT DESCRIPTION
  const projectDescription =
    thisProject?.localizedDescription?.textMap[getCurrentLanguage()] ??
    thisProject?.description;

  // PROJECT ADDRESS
  let formattedAddress = buildAddress(thisProject?.address);

  // PROJECT DELIVERY
  const projectDeliveryPeriod =
    thisProject?.deliveryPeriod?.textMap[getCurrentLanguage()] ??
    thisProject?.deliveryPeriod?.textMap['en'] ??
    '';

  // PROJECT VAT DESCRIPTION
  const projectVatDescription =
    thisProject?.vatDescription?.textMap[getCurrentLanguage()] ??
    thisProject?.vatDescription?.textMap['en'] ??
    '';

  // PROJECT FROM PRICE & UNIT ADDITIONAL INFORMATION
  let minUnitPrice = Infinity;
  let minUnitsSurface = Infinity;
  let maxUnitsSurface = 0;
  let minUnitBedrooms = Infinity;
  let maxUnitBedrooms = 0;
  let minUnitBathrooms = Infinity;
  let maxUnitBathrooms = 0;
  let showBathroomsInDetails = false;
  let showBedroomsInDetails = false;
  let showSurfaceInDetails = false;

  unitList.forEach((unit: IUnit) => {
    if (unit?.state === 'SOLD' || unit?.state === 'IN_OPTION') return;

    const [showPrice, showBedrooms, showBathrooms, showSurface] =
      checkUnitFieldsVisibility(unit, [
        GeneralFieldType.price,
        GeneralFieldType.numberOfBedrooms,
        GeneralFieldType.numberOfBathrooms,
        GeneralFieldType.surface
      ]);

    if (showPrice && unit?.unitPrice?.price < minUnitPrice) {
      minUnitPrice = unit.unitPrice.price;
    }
    if (unit?.unitMetadata) {
      const { numberOfBedrooms, numberOfBathrooms, surface } =
        unit.unitMetadata;

      if (showSurface) {
        if (surface > maxUnitsSurface) maxUnitsSurface = surface;
        if (surface < minUnitsSurface) minUnitsSurface = surface;
        showSurfaceInDetails = true;
      }

      // Include unit's bathrooms and bedrooms only if they are
      // not hidden by unit type
      if (showBedrooms) {
        showBedroomsInDetails = true;
        if (numberOfBedrooms < minUnitBedrooms)
          minUnitBedrooms = numberOfBedrooms;
        if (numberOfBedrooms > maxUnitBedrooms)
          maxUnitBedrooms = numberOfBedrooms;
      }

      if (showBathrooms) {
        showBathroomsInDetails = true;
        if (numberOfBathrooms < minUnitBathrooms)
          minUnitBathrooms = numberOfBathrooms;
        if (numberOfBathrooms > maxUnitBathrooms)
          maxUnitBathrooms = numberOfBathrooms;
      }
    }
  });
  numeral.locale('nl-nl');
  const currencySymbol = currencySymbolMap('EUR');
  const unitPrice = `${currencySymbol} ${numeral(minUnitPrice).format()}`;

  let unitTypes: Array<string> = [];
  unitList.forEach((x: IUnit) => {
    if (x?.state === 'SOLD' || x?.state === 'IN_OPTION') return;

    const unitTypeName =
      x?.unitType?.name?.textMap[getCurrentLanguage()] ??
      x?.unitType?.name?.textMap['en'] ??
      x?.unitType?.title;

    if (unitTypeName) {
      if (unitTypes.indexOf(unitTypeName) === -1) {
        unitTypes.push(unitTypeName);
      }
    }
  });

  unitTypes = unitTypes.filter((x) => x !== undefined);

  // Logic to not show a range if min === max
  const surfaceRange =
    minUnitsSurface !== maxUnitsSurface
      ? `${minUnitsSurface}m² - ${maxUnitsSurface}m²`
      : `${minUnitsSurface}m²`;
  const bedroomsRange =
    minUnitBedrooms !== maxUnitBedrooms
      ? `${minUnitBedrooms} - ${maxUnitBedrooms}`
      : `${minUnitBedrooms}`;
  const bathroomsRange =
    minUnitBathrooms !== maxUnitBathrooms
      ? `${minUnitBathrooms} - ${maxUnitBathrooms}`
      : `${minUnitBathrooms}`;
  const additionalInformation = [];

  if (unitTypes.length > 0) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'building']} size="sm" />,
      text: unitTypes.join(', ')
    });
  }
  if (showSurfaceInDetails) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'vector-square']} size="sm" />,
      text: `${localizer.projectDetailPage.additionalInformation.unitsSurface}: ${surfaceRange}`
    });
  }
  if (showBedroomsInDetails) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'bed']} size="sm" />,
      text: `${localizer.projectDetailPage.additionalInformation.bedrooms}: ${bedroomsRange}`
    });
  }
  if (showBathroomsInDetails) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'bath']} size="sm" />,
      text: `${localizer.projectDetailPage.additionalInformation.bathrooms}: ${bathroomsRange}`
    });
  }
  if (projectDeliveryPeriod) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'calendar-alt']} size="sm" />,
      text: projectDeliveryPeriod
    });
  }

  if (projectVatDescription) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'money-bill-wave']} size="sm" />,
      text: projectVatDescription
    });
  }

  const renderProjectTour = useCallback(
    (givenProps: any) => {
      return (
        <ProjectTour
          firstImage={'test'}
          onOpenUnitPage={(unit: any, parentMediaViewId?: string) => {
            DataStateDispatch({
              type: 'setScrollToTopOfUnitPage',
              payload: true
            });
            document.body.style.overflow = 'hidden';

            const parsed: any = qs.parse(window.location.search);

            [{ pmv: parentMediaViewId }].forEach((param) => {
              const [key, value] = Object.entries(param)[0];
              parsed[key] = value;
            });

            navigate(
              `/vault/${vaultId}/project/${projectId}/unit/${
                unit.objectId
              }?${qs.stringify(parsed)}`
            );
          }}
          contentCollection={fullProjectContentCollection}
          navigationItem={projectExploreNavItem}
          vaultId={vaultId}
          projectId={projectId}
          allUnitList={unitList}
          // @ts-ignore
          hidePrices={!!thisProject?.settings?.hidePrice}
          {...givenProps}
        />
      );
    },
    [
      projectId,
      vaultId,
      projectExploreNavItem,
      fullProjectContentCollection,
      unitList
    ]
  );

  useEffect(() => {
    const handleScroll = () => {
      const showForDesktop = window.scrollY > 100;
      const showForMobile = window.scrollY > 72;
      setIsFixed(isMobileOnly ? showForMobile : showForDesktop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderUnitTour = useCallback(
    (givenProps: any) => (
      <ProjectTour
        firstImage={'test'}
        onOpenUnitPage={(unit: any, ss?: string) => {
          DataStateDispatch({
            type: 'setScrollToTopOfUnitPage',
            payload: true
          });
          document.body.style.overflow = 'hidden';
          navigate(
            `/vault/${vaultId}/project/${projectId}/unit/${unit.objectId}` +
              window.location.search
          );
        }}
        contentCollection={fullProjectContentCollection}
        navigationItem={projectExploreNavItem}
        vaultId={vaultId}
        projectId={projectId}
        hideAdditionalInfoSwitcher={true}
        hideControls={true}
        allUnitList={unitList}
        // @ts-ignore
        hidePrices={!!thisProject?.settings?.hidePrice}
        {...givenProps}
      />
    ),
    [projectId, vaultId, projectExploreNavItem, fullProjectContentCollection]
  );

  const scrollToUnitsButton = (
    <ScrollToUnitsButton onClick={scrollToUnitsSection}>
      <ScrollToUnitsIcon icon={['far', 'arrow-down']} size="sm" />
      {localizer.projectDetailPage.scrollToFindUnits}
    </ScrollToUnitsButton>
  );

  const onBackToProjectsClick = () => {
    if (marketplaceId && sessionId && visitorId) {
      const trackingData = {
        actionName: 'trackMarketplaceNavigation',
        targetPage: 'projects',
        marketplaceId,
        visitorId,
        sessionId
      };
      Tracking.trackMarketplaceAction(trackingData).catch(() => {});
    }
    navigate(`/projects${window.location.search}`);
  };

  const ProjectPageContent = (
    <PageWrapper hideMenu={hideMenu as boolean}>
      <TopBarWrapper>
        <GoBackButton
          onClick={onBackToProjectsClick}
          isFixed={isFixedBackButton}
          isMobile={isMobileOnly}
        >
          <BackIconWrapper>
            <span className="material-symbols-outlined">arrow_back</span>
          </BackIconWrapper>
          {!isMobileOnly && !isFixedBackButton && (
            <BackText>{localizer.projectDetailPage.backToOverview}</BackText>
          )}
        </GoBackButton>
        <RightPartSection>
          {!disableAccount && <FavouriteButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              AuthStateDispatch({
                type: 'toggleFavouriteProject',
                payload: { projectId, vaultId }
              });
            }}
          >
            <FavouriteIcon
              icon={[
                favouriteProjects?.find(
                  (x: any) => x.favoriteProjectId === projectId
                )
                  ? 'fas'
                  : 'far',
                'heart'
              ]}
              size="sm"
            />
          </FavouriteButton>}
          <StyledShareButton projectTitle={projectTitle as string} />
        </RightPartSection>
      </TopBarWrapper>
      <ResourcesWrapper>
        <Resources
          contentItemList={contentItemList}
          previewContent={previewContent}
          fetchingContent={fetchingContent}
          hideModal
        />
      </ResourcesWrapper>
      <MainProjectInfoSection>
        <ProjectTitleAndAddress>
          <ProjectTitle>{projectTitle}</ProjectTitle>
          <ProjectAddress
            onClick={() => {
              setIsMapOpened(true);
            }}
            title={localizer.projectDetailPage.viewOnTheMap}
          >
            <MapButtonWrapper>
              <MapButton>
                <AddressIcon icon={['far', 'map-marker-alt']} size="sm" />
              </MapButton>
            </MapButtonWrapper>
            <VerticalWrapper>
              <AddressName>{formattedAddress}</AddressName>
              <TooltipMessage>
                {localizer.projectDetailPage.viewOnTheMap}
              </TooltipMessage>
            </VerticalWrapper>
          </ProjectAddress>
        </ProjectTitleAndAddress>

        <PriceBadgeWrapper>
          {minUnitPrice !== Infinity &&
            // @ts-ignore
            (thisProject.settings.hidePrice ? (
              requestUnitPriceButton
            ) : (
              <PriceBadge
                color={BadgeColor.beige}
                information={`${localizer.projectDetailPage.from} ${unitPrice}`}
                fontSize={1.875}
              />
            ))}
          {isMobileOnly && scrollToUnitsButton}
        </PriceBadgeWrapper>

        <ProjectAdditionalInformation items={additionalInformation} />

        <ProjectDescription>
          <ProjectDescriptionLabel>
            {localizer.projectDetailPage.projectDescriptionLabel.toUpperCase()}
          </ProjectDescriptionLabel>
          <ProjectDescriptionContent>
            <RichTextRenderer richText={projectDescription} />
          </ProjectDescriptionContent>
        </ProjectDescription>
        <GetInTouchZone>
          {!isMobileOnly && scrollToUnitsButton}
          <GetInTouchCTA
            logoUri={companyLogoUri}
            trackingData={basicTrackingData}
            location={'centerProjectPage'}
            // @ts-ignore
            projectSettings={thisProject?.settings ?? {}}
            projectId={projectId}
            marketplaceId={marketplaceId}
          />
          {!disableAccount && <CreateAccountBlock />}
        </GetInTouchZone>
      </MainProjectInfoSection>
      <UnitListWrapper>
        {unitList?.length > 0 &&
        unitList.every((unit: IUnit) => unit.loaded) ? (
          <UnitList
            title={
              localizer.unitListComponent[
                isMobileOnly ? 'units' : 'unitInProject'
              ]
            }
            withFilters={true}
            withSorting={true}
            units={unitList}
            renderProjectTour={projectExploreNavItem ? renderProjectTour : null}
            showBathroomsInDetails={showBathroomsInDetails}
            showBedroomsInDetails={showBedroomsInDetails}
            // @ts-ignore
            hidePrices={!!thisProject?.settings?.hidePrice}
            vaultId={vaultId}
            projectId={projectId}
          />
        ) : null}
      </UnitListWrapper>

      <ExposedFormSection>
        <GetInTouchFormModal
          isModal={false}
          projectThumbnail={previewContent[0]}
          projectTitle={projectTitle}
          projectAddress={formattedAddress}
          trackingData={basicTrackingData}
          location={'bottomProjectPage'}
        />
      </ExposedFormSection>
      {showContactUsForm && (
        <GetInTouchFormModal
          onClose={() => {
            ContactUsStateDispatch({
              type: 'setShowContactUsForm',
              payload: false
            });

            if (basicTrackingData) {
              Tracking.trackGetInTouchInteraction({
                ...basicTrackingData,
                locationOnPage: formLocation,
                actionName: 'formClosed'
              }).catch(() => console.warn(`Could not track ${'formClosed'}`));
            }
          }}
          unitObjectId={unitObjectId}
          projectThumbnail={previewContent[0]}
          projectTitle={projectTitle}
          projectAddress={formattedAddress}
          trackingData={basicTrackingData}
          location={formLocation}
        />
      )}
      {thisProject?.address && (
        <MapModal
          isMapOpened={isMapOpened}
          onClose={() => {
            setIsMapOpened(false);
          }}
          projectThumbnail={buildAssetURIWithOptions(
            'h=150',
            thisProject.thumbnailUri
          )}
          address={thisProject?.address}
        />
      )}
    </PageWrapper>
  );

  return (
    <>
      <Routes>
        <Route
          path="unit/:unitId"
          element={
            <UnitDetailPage
              returnToProjectPage={returnToProjectPage}
              unitList={unitList}
              projectTitle={projectTitle}
              companyLogoUri={companyLogoUri}
              projectThumbnail={previewContent[0]}
              projectAddress={formattedAddress}
              renderUnitTour={projectExploreNavItem ? renderUnitTour : null}
              vaultId={vaultId}
              projectId={projectId}
              marketplaceId={marketplaceId}
            />
          }
        />
      </Routes>
      {ProjectPageContent}
    </>
  );
};

export default ProjectDetailPage;

import { useEffect, useRef } from 'react';

// Helpers
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 18px;
  right: -30px;
`;

const TippyContent = styled.div<StyledProps<{ styles?: string }>>`
  word-wrap: normal;
  font-weight: 400;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  line-height: 1.33;
  white-space: normal;
  word-break: keep-all;
  min-width: 250px;
  max-width: 275px;
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: rgba(46, 49, 56, 0.9);
  color: ${({ theme }) => theme.white};
  ${({ styles }) => styles}
  a {
    color: inherit !important;
    text-decoration: none;
    font-weight: 600;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)<StyledProps<{ styles?: string }>>`
  font-size: 1rem;
  color: ${({ theme }) => theme.gray50};
  margin-left: 12px;
  cursor: pointer;
  ${({ styles }) => styles}
`;

const InfoIcon = ({
  tippyText,
  dataTestId,
  tippyContentStyles,
  iconStyles,
  parentRef,
  ...tippyProps
}: any) => {
  const tippyRef = useRef<any>();

  useEffect(() => {
    const handleScroll = () => {
      tippyRef.current && tippyRef.current._tippy.hide();
    };

    if (parentRef.current) {
      parentRef.current.addEventListener('scroll', handleScroll);
    }
  }, [parentRef]);

  return (
    <Tippy
      key="tooltip"
      ref={tippyRef}
      theme="prompto"
      placement="bottom-start"
      arrow={false}
      zIndex={5000}
      content={
        <TippyContent
          data-testid={`${dataTestId}-text`}
          styles={tippyContentStyles}
        >
          {tippyText}
        </TippyContent>
      }
      hideOnClick={true}
      interactive={true}
      offset={[12, 14]}
      {...tippyProps}
    >
      <Wrapper
        data-testid={`${dataTestId}-icon`}
        onClick={(e) => e.stopPropagation()}
      >
        <StyledIcon
          icon={['far', 'info-circle']}
          size="1x"
          styles={iconStyles}
          onClick={(e) => e.stopPropagation()}
        />
      </Wrapper>
    </Tippy>
  );
};

export default InfoIcon;

import { useEffect, useRef, useState } from 'react';

// Helpers
import { motion } from 'framer-motion';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import localizer from 'src/localization/localizer';
import { useProjectsState } from 'src/store/ProjectsStore';
import { useAppState } from 'src/store/AppStore';
import { useNavigate } from 'react-router-dom';

// import EriboLogo from 'src/resources/images/logos/eribo.png';
// import ImmobelLogo from 'src/resources/images/logos/immobel.png';
import POCLogo from 'src/resources/images/logos/POC.png';
import AlidesLogo from 'src/resources/images/logos/alides.png';
import HybomaLogo from 'src/resources/images/logos/hyboma.png';
import VestaLogo from 'src/resources/images/logos/vesta.png';
import VestioLogo from 'src/resources/images/logos/vestio.png';
import IonLogo from 'src/resources/images/logos/ion.png';
import OryxLogo from 'src/resources/images/logos/oryx.png';
import CaapLogo from 'src/resources/images/logos/caap.png';

// Styling
import styled, { StyledProps, withTheme } from 'styled-components';
import { Text } from './sharedStyledComponents';
import { forBiggerThan } from 'src/helpers/ui';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import { Marketplace } from '@/libs/prompto-api/src';

const Wrapper = styled(motion.div)`
  width: 100%;
  background: ${({ theme }) => theme.white};
  position: relative;
  padding: 8px 16px 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 41px;
    top: -40px;
    left: 0;
    right: 0;
    border-radius: 40px 40px 0 0;
    background: ${({ theme }) => theme.white};
  }
  ${forBiggerThan.mobile`
    padding: 40px 64px 0;
  `}
`;

const Logos = styled.div`
  display: flex;
  gap: 16px;
  width: 70%;
  overflow: hidden;
  margin: 32px 0 40px;
  ${forBiggerThan.mobile`
    margin: 32px 0 40px;
    gap: 64px;
  `}
`;

const BlockTitle = styled(Text)``;

// const LogoImage = styled.img<StyledProps<{pos: number}>>`
//   width: 96px;
//   height: 96px;
//   object-fit: contain;
//   transform: translateX(-${({pos}) => pos}px);
//   ${forBiggerThan.mobile`
//     width: 160px;
//     height: 160px;
//   `}
// `;

const IMAGE_WIDTH = 160;
const GAP = 64;
const TOTAL = IMAGE_WIDTH + GAP;
const LogoImage = styled.img.attrs((props) => ({
  style: {
    //@ts-ignore
    transform: `translateX(-${props.pos}px)`
  }
}))`
  width: 96px;
  height: 96px;
  object-fit: contain;

  ${forBiggerThan.mobile`
  width: 160px;
  height: 160px;
`}
`;

const logos = [
  POCLogo,
  HybomaLogo,
  AlidesLogo,
  OryxLogo,
  CaapLogo,
  VestaLogo,
  VestioLogo,
  IonLogo
];

interface DeveloperBlockProps {
  theme: { white: string; black: string };
}

const DevelopersBlock = (props: DeveloperBlockProps) => {
  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const { ProjectsState, ProjectsStateDispatch } = useProjectsState();
  const { allVaultLogos } = ProjectsState;

  const ref = useRef(null);
  const { gotVisible } = useIntersectionObserver(ref);
  const navigate = useNavigate();

  const developersVisibleAtOneTime = 7;
  const [animPos, setAnimPos] = useState<number>(0);
  const [logosToShow, setLogosToShow] = useState<Array<string>>([]);

  const interval = useRef<any>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setAnimPos((current) => current + 4);
    }, 50);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (animPos === TOTAL) {
      setLogosToShow((current) => {
        let newArray = [...current];
        const wasFirst = newArray.shift();
        if (wasFirst) {
          newArray.push(wasFirst);
        }

        return newArray;
      });

      setAnimPos(0);
    }
  }, [animPos, logosToShow]);

  useEffect(() => {
    if (allVaultLogos) {
      const urls = Object.keys(allVaultLogos).map((x: any) => {
        if (x === '6O6sKS0Eyp') return '';
        return buildAssetURIWithOptions('h=160', allVaultLogos[x]);
      });

      if (urls?.length > 0) {
        setLogosToShow(urls.filter((x) => x !== ''));
      } else {
        setLogosToShow(logos);
      }
    } else if (marketplaceId) {
      const projectsFilter = { limit: 200 };
      Marketplace.searchProjects(marketplaceId, projectsFilter).then(
        (result) => {
          const projects = result.data?.projectList ?? [];

          ProjectsStateDispatch({
            type: 'setProjectsData',
            payload: {
              unalteredProjects: projects,
              allVaultLogos: result.data?.vaultIdToLogoUriMap ?? []
            }
          });
        }
      );
    }
  }, [allVaultLogos, logos, marketplaceId]);

  return (
    <Wrapper
      ref={ref}
      animate={{ opacity: gotVisible ? 1 : 0, y: gotVisible ? 0 : 100 }}
      transition={{ ease: 'linear', duration: 0.3 }}
      onClick={() => {
        navigate(`/developers${window.location.search}`);
      }}
    >
      <BlockTitle color={props.theme.black}>
        {localizer.formatString(
          localizer.homePage.providedBy,
          <span>{localizer.homePage.theBest}</span>
        )}
      </BlockTitle>

      <Logos>
        {logosToShow.slice(0, developersVisibleAtOneTime).map((logo, idx) => (
          //@ts-ignore
          <LogoImage src={logo} key={logo} pos={animPos} />
        ))}
      </Logos>
    </Wrapper>
  );
};

export default withTheme(DevelopersBlock);

import { useRef, useEffect, useState } from 'react';

// Helpers
import { isMobile } from 'react-device-detect';
import { displayLocalizedValue } from 'src/helpers/utils';
import { ExpandDirections, getDisplayDirection } from './Spot';
import { ContentCollection } from '@prompto-api';
import { usePrevious } from '@prompto-helpers';
import isEqual from 'lodash.isequal';
import env from 'src/environment';

// Components
import Loader from 'src/components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdealImage } from '@prompto-ui';
import { RichTextRenderer } from '@prompto-ui';
import StyledScrollbarWrapper from 'src/components/StyledScrollbarWrapper';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { motion } from 'framer-motion';

// Types
interface ISize {
  width: number;
  height: number;
}

interface IMainWrapper {
  size?: ISize;
  isTransparentBackground: boolean;
  useInheritPosition: boolean;
  displayDirection: any;
  yPositionCorrection: number;
  offset: any;
  source: string;
  givenDirection: any;
  enlarged?: boolean;
  maxHeight: number;
}

interface IEnlargedUspData {
  mediaType?: string;
  mediaContent: string;
  headline: string;
  description: string;
  richTextDescription: any;
  visibilitySettings: any;
  shouldFit: boolean;
  isVideoMuted: boolean;
  isPinned?: boolean;
  ratio: number;
  size: number;
}

interface UspSpotTooltipProps {
  spotObject: any;
  uspSpotLocation?: any;
  uspSpotAbsoluteLocation?: {
    x: number;
    y: number;
  };
  useInheritPosition?: boolean;
  isPinned?: boolean;
  setEnlargedUspData?: (data: IEnlargedUspData) => void;
  enlargeUsp?: (e: any) => void;
  showTooltip?: boolean;
  onSizeComputed?: (size: ISize) => void;
  source?: any;
  givenDirection?: any;
}

const MainWrapper = styled(motion.div)<StyledProps<IMainWrapper>>`
  width: ${({ size }) => size?.width ?? 0}px;
  height: auto;
  max-height: ${({ maxHeight }) => maxHeight - 40}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column;
  background-color: ${({ theme, isTransparentBackground }) =>
    isTransparentBackground ? 'transparent' : theme.white};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
  cursor: pointer;
  transition: all 160ms ease-out;
  ${({
    useInheritPosition,
    size,
    displayDirection,
    offset,
    source,
    givenDirection
  }) => {
    if (useInheritPosition) {
      return css`
        position: relative;
      `;
    } else if (offset) {
      const yDirection =
        source === 'polygon' ? givenDirection.y : displayDirection.y;
      const toTop =
        source === 'polygon'
          ? (size?.height ?? 0) * -1
          : offset.yTop - (size?.height ?? 0);
      const toBottom = source === 'polygon' ? 0 : offset.yBottom;
      const top = yDirection === 'top' ? toTop : toBottom;
      const left =
        displayDirection.x === 'left'
          ? ((size?.width ?? 0) - offset.xLeft) * -1
          : offset.xRight * -1;
      return css`
        position: absolute;
        top: ${top}px;
        left: ${left}px;
      `;
    }
  }}
  ${isMobile &&
  `
  position: static;
  width: 100%;
  max-width: 100%;
  `}
  ${(props) =>
    props.enlarged &&
    `
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

const MainInfoWrapper = styled.div`
  width: calc(100% - ${isMobile ? '30px' : '60px'});
  padding: 20px 0 20px;
  box-sizing: border-box;
`;

const Headline = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MediaWrapper = styled.div<StyledProps<{ size?: ISize }>>`
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${({ size }) =>
    size &&
    css`
      width: ${size.width}px;
      height: ${size.height}px;
    `}
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  z-index: 1;
`;

const StyledVideo = styled.video<StyledProps<{ shouldfit: boolean }>>`
  position: absolute;
  top: 0;
  left: 0;
  object-position: center;
  object-fit: ${({ shouldfit }) => (shouldfit ? 'contain' : 'cover')};
  width: 100%;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  margin-top: -25px;
`;

const ToggleVolumeButton = styled.button`
  position: absolute;
  z-index: 4;
  right: 1rem;
  bottom: 1rem;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 1rem;
`;

const offsetMargin = 20;
const defaultTooltipWidth = 330;

const UspSpotTooltip = ({
  spotObject,
  uspSpotLocation,
  uspSpotAbsoluteLocation = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2
  },
  useInheritPosition = false,
  isPinned = false,
  setEnlargedUspData = () => {},
  enlargeUsp = () => {},
  showTooltip,
  onSizeComputed = () => {},
  source,
  givenDirection
}: UspSpotTooltipProps) => {
  const [linkedContent, setLinkedContent] = useState<any>();
  const [isFetchingLinkedContent, setIsFetchingLinkedContent] =
    useState<boolean>(false);

  const [uspMediaType, setUspMediaType] = useState<string>();
  const [imageContent, setImageContent] = useState();
  const [videoContent, setVideoContent] = useState();
  const [mediaShouldFit] = useState(true);
  const [mediaContentSize, setMediaContentSize] = useState<ISize>();
  const [mediaContentHeightToWidthRatio, setMediaContentHeightToWidthRatio] =
    useState(0);

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [isVideoMuted, setVideoMuted] = useState(true);

  const [tooltipSize, setTooltipSize] = useState<ISize>({
    width: isMobile ? 200 : defaultTooltipWidth,
    height: 250
  });
  const [tooltipHeightAdjusted, setTooltipHeightAdjusted] = useState(false);
  const [displayDirection, setDisplayDirection] = useState({
    x: ExpandDirections.right,
    y: ExpandDirections.top
  });
  const [yPositionCorrection, setYPositionCorrection] = useState(0);
  const [visibilitySettings] = useState(spotObject?.usp?.uspVisibilitySettings);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [maxTooltipWidth, setMaxTooltipWidth] = useState<number | null>(null);

  const headline = displayLocalizedValue(spotObject?.usp?.headline?.textMap);
  const description = displayLocalizedValue(
    spotObject?.usp?.description?.textMap
  );
  const richTextDescription = displayLocalizedValue(
    spotObject?.usp?.richTextDescription?.textMap
  );

  const [imageSizeCalculated, setImageSizeCalculated] = useState(false);
  const [showOnlyMedia, setShowOnlyMedia] = useState(true);
  const [mediaContentOffset, setMediaContentOffset] = useState<any>(null);
  const [videoRatio, setVideoRatio] = useState<number | null>(null);

  const previousShowTooltip = usePrevious(showTooltip);
  const previousTooltipSize = usePrevious(tooltipSize);

  const elementId = 'uspSpot-' + spotObject.objectId;

  useEffect(() => {
    if (!isEqual(tooltipSize, previousTooltipSize)) {
      onSizeComputed(tooltipSize);
    }
  }, [tooltipSize, onSizeComputed, previousTooltipSize]);

  useEffect(() => {
    if (visibilitySettings?.showMedia === false) return;
    if (isMobile) return;
    const enlargedUspData: IEnlargedUspData = {
      mediaType: uspMediaType,
      mediaContent: linkedContent?.contentUri,
      headline,
      description,
      richTextDescription,
      visibilitySettings,
      shouldFit: mediaShouldFit,
      isVideoMuted,
      isPinned,
      ratio: mediaContentHeightToWidthRatio,
      size: linkedContent?.originalImageSize
    };
    setEnlargedUspData(enlargedUspData);
  }, [
    setEnlargedUspData,
    uspMediaType,
    linkedContent,
    headline,
    description,
    richTextDescription,
    visibilitySettings,
    mediaShouldFit,
    isVideoMuted,
    isPinned,
    mediaContentHeightToWidthRatio
  ]);

  useEffect(() => {
    if (uspMediaType === 'video') {
      if (maxTooltipWidth && videoRatio) {
        setMediaContentSize({
          width: maxTooltipWidth,
          height: maxTooltipWidth * videoRatio
        });
      }
    }
  }, [videoRatio, maxTooltipWidth, uspMediaType]);

  useEffect(() => {
    if (uspMediaType === 'video') {
      let video: any = document.createElement('video');
      video.src = videoContent;
      video.addEventListener(
        'loadedmetadata',
        () => {
          setVideoRatio(video.videoHeight / video.videoWidth);
        },
        false
      );
    }
  }, [uspMediaType, videoContent]);

  useEffect(() => {
    if (!spotObject.size) {
      // 330px is a medium size width which is default one
      setMaxTooltipWidth(330);
    } else if (spotObject.size && spotObject.size !== 1) {
      setMaxTooltipWidth(spotObject.size);
    } else {
      if (tooltipSize?.width) {
        const width =
          tooltipSize?.width < window.innerWidth * 0.9
            ? tooltipSize?.width
            : window.innerWidth * 0.9;
        setMaxTooltipWidth(width);
      } else {
        setMaxTooltipWidth(defaultTooltipWidth);
      }
    }
  }, [spotObject, linkedContent, tooltipSize]);

  useEffect(() => {
    if (visibilitySettings?.showMedia === false) {
      setLinkedContent(-1);
      return;
    }
    if (linkedContent || isFetchingLinkedContent) return;
    if (spotObject?.usp?.mediaCollection) {
      setIsFetchingLinkedContent(true);
      ContentCollection.get(spotObject?.usp?.mediaCollection.objectId).then(
        (result: any) => {
          if (result) {
            const { data } = result;
            const { vmContentCollection } = data;
            if (
              vmContentCollection?.vmContentItemList?.length > 0 &&
              vmContentCollection?.vmContentItemList[0]?.contentItemState !==
                'archived'
            ) {
              // The usp feature only allows for one content item for now
              setLinkedContent(vmContentCollection?.vmContentItemList[0]);
            } else {
              setLinkedContent(-1);
            }
          } else {
            setLinkedContent(-1);
          }
        }
      );
    } else {
      setLinkedContent(-1);
    }
  }, [spotObject, linkedContent, visibilitySettings, isFetchingLinkedContent]);

  useEffect(() => {
    if (tooltipHeightAdjusted && !mediaContentSize) return;

    if (
      containerRef.current &&
      linkedContent &&
      (imageContent || (videoContent && videoLoaded)) &&
      visibilitySettings?.showMedia !== false
    ) {
      const maxHeight = containerRef.current.offsetHeight;
      const size = {
        width: maxTooltipWidth ?? defaultTooltipWidth,
        height: maxHeight
      };
      setTooltipSize(size);
      setTooltipHeightAdjusted(true);
    } else if (containerRef.current && linkedContent === -1) {
      const maxHeight = containerRef.current.offsetHeight;
      const size = {
        width: maxTooltipWidth ?? defaultTooltipWidth,
        height: maxHeight
      };
      setTooltipSize(size);
      setTooltipHeightAdjusted(true);
    }
  }, [
    containerRef,
    linkedContent,
    videoLoaded,
    videoContent,
    imageContent,
    visibilitySettings,
    tooltipHeightAdjusted,
    mediaContentSize,
    maxTooltipWidth
  ]);

  useEffect(() => {
    if (source === 'polygon') return;
    if (!tooltipHeightAdjusted) return;
    if (tooltipSize && !useInheritPosition) {
      // we multiply width by 2 due to design changes between usp spot tooltip default position and unit spot one
      const displayDir = getDisplayDirection(
        uspSpotLocation,
        maxTooltipWidth ?? 0 * 2,
        tooltipSize.height,
        window.innerWidth,
        'tourContainer'
      );

      if (displayDir.y === 'top') {
        const tooltipIsFullyVisible =
          uspSpotAbsoluteLocation.y - tooltipSize.height > 0;
        if (!tooltipIsFullyVisible && uspSpotAbsoluteLocation.y > 0) {
          // 5px is to add a visible gap between a tooltip and a tour image border
          setYPositionCorrection(
            tooltipSize.height - uspSpotAbsoluteLocation.y + 5
          );
        }
      }
      setDisplayDirection(displayDir);
    }
  }, [
    uspSpotLocation,
    maxTooltipWidth,
    tooltipSize,
    uspSpotAbsoluteLocation,
    useInheritPosition,
    tooltipHeightAdjusted,
    source
  ]);

  useEffect(() => {
    if (linkedContent) {
      switch (linkedContent.contentItemType) {
        case 'image':
          setUspMediaType('image');
          let imageUri = linkedContent?.contentUri;
          if (imageUri) {
            setImageContent(imageUri);
          }
          break;
        case 'video':
          setUspMediaType('video');
          setVideoContent(linkedContent.contentUri);
          break;
        default:
          setUspMediaType('no_media');
          break;
      }
    }
  }, [linkedContent]);

  useEffect(() => {
    setShowOnlyMedia(
      !visibilitySettings?.showHeadline &&
        !visibilitySettings?.showDescription &&
        visibilitySettings?.showHeadline !== undefined &&
        visibilitySettings?.showDescription !== undefined
    );
  }, [visibilitySettings]);

  useEffect(() => {
    if (tooltipSize && !useInheritPosition) {
      // Calculate offset to the left of the screen
      const xLeft = Math.min(
        Math.max(
          tooltipSize.width + offsetMargin - uspSpotAbsoluteLocation.x,
          0
        ),
        tooltipSize.width
      );
      // Calculate offset to the right of the screen
      const xRight = Math.min(
        Math.max(
          tooltipSize.width +
            offsetMargin +
            uspSpotAbsoluteLocation.x -
            window.innerWidth,
          0
        ),
        tooltipSize.width
      );
      // Calculate offset to the top of the screen
      const yTop = Math.min(
        Math.max(
          tooltipSize.height + offsetMargin - uspSpotAbsoluteLocation.y,
          0
        ),
        tooltipSize.height
      );
      // Calculate offset to the bottom of the screen
      const yBottom = Math.min(
        Math.max(
          tooltipSize.height +
            offsetMargin +
            uspSpotAbsoluteLocation.y -
            window.innerHeight,
          0
        ),
        tooltipSize.height
      );

      setMediaContentOffset({ xLeft, xRight, yTop, yBottom });
    }
  }, [tooltipSize, uspSpotAbsoluteLocation, useInheritPosition, source]);

  const tourContainer = document.getElementById('tourContainer');
  const tourHeight = tourContainer?.getBoundingClientRect().height ?? 0;
  const tourWidth = tourContainer?.getBoundingClientRect().width ?? 0;

  const content =
    uspMediaType ||
    !isMobile ||
    (!uspMediaType && visibilitySettings?.showMedia === false) ? (
      <>
        {uspMediaType === 'image' && (
          <MediaWrapper ref={wrapperRef} size={mediaContentSize}>
            {!imageLoaded && <ImagePlaceholder />}
            {imageContent && (
              <IdealImage
                key={`uspImage`}
                contentUri={imageContent}
                fallbackUri={imageContent}
                imageSize={{
                  width: mediaContentSize?.width ?? 10,
                  height: mediaContentSize?.height ?? 10
                }}
                containerSize={{
                  width: mediaContentSize?.width ?? 10,
                  height: mediaContentSize?.height ?? 10
                }}
                mustFillParent={true}
                /* The below code is an event handler function that is triggered when an image is
                loaded. It calculates and sets the size of the image based on the available space
                and the aspect ratio of the image. It takes into account the maximum width and
                height constraints and adjusts the image size accordingly. The calculated size is
                then stored in the state variables for further use. */
                onLoad={(
                  iWidth?: number,
                  iHeight?: number,
                  target?: any,
                  isPlaceholder?: boolean
                ) => {
                  if (isPlaceholder || imageSizeCalculated) return;
                  setImageSizeCalculated(true);
                  setImageLoaded(true);
                  // adopt media content size
                  const iRatio = target.naturalHeight / target.naturalWidth;
                  setMediaContentHeightToWidthRatio(iRatio);

                  const maxHeight = Math.min(
                    maxTooltipWidth === 1 || !maxTooltipWidth
                      ? linkedContent?.originalImageSize?.height
                      : maxTooltipWidth * iRatio,
                    tourHeight - 40
                  );
                  const maxWidth = Math.min(
                    maxTooltipWidth === 1 || !maxTooltipWidth
                      ? linkedContent?.originalImageSize?.width
                      : maxTooltipWidth,
                    tourWidth * 0.9
                  );

                  const imageWidth = maxWidth ?? target.naturalWidth;
                  const imageHeight = maxHeight ?? target.naturalHeight;

                  if (iRatio < 1) {
                    const width = imageWidth > maxWidth ? maxWidth : imageWidth;
                    const height = width * iRatio;
                    setMediaContentSize({ width, height });
                  } else {
                    const height =
                      imageHeight > maxHeight ? maxHeight : imageHeight;
                    const width = height / iRatio;
                    setMediaContentSize({ width, height });
                  }
                }}
                shouldFit={isPinned ? true : mediaShouldFit}
                baseImageUrl={env().baseImageUrl}
              />
            )}
          </MediaWrapper>
        )}

        {videoContent && (
          <MediaWrapper size={mediaContentSize}>
            <StyledVideo
              src={videoContent}
              autoPlay={true}
              controls={false}
              onLoadedData={() => {
                setVideoLoaded(true);
              }}
              preload="auto"
              playsInline={true}
              //   type="video/mp4"
              shouldfit={mediaShouldFit}
              muted={isVideoMuted}
            />
            <ToggleVolumeButton
              onClick={(e) => {
                e.stopPropagation();
                setVideoMuted(!isVideoMuted);
              }}
            >
              <FontAwesomeIcon
                icon={['fal', isVideoMuted ? 'volume-slash' : 'volume-up']}
                size="1x"
              />
            </ToggleVolumeButton>
          </MediaWrapper>
        )}

        {[
          visibilitySettings?.showHeadline !== false,
          visibilitySettings?.showDescription !== false
        ].some(Boolean) && (
          <MainInfoWrapper>
            {visibilitySettings?.showHeadline !== false && (
              <Headline>{headline}</Headline>
            )}
            {visibilitySettings?.showDescription !== false && (
              <StyledScrollbarWrapper size={'40vh'}>
                <RichTextRenderer
                  richText={richTextDescription}
                  fallbackValue={description}
                />
              </StyledScrollbarWrapper>
            )}
          </MainInfoWrapper>
        )}
      </>
    ) : (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return (
    <MainWrapper
      ref={containerRef}
      maxHeight={tourHeight}
      size={tooltipSize}
      displayDirection={displayDirection}
      givenDirection={givenDirection}
      yPositionCorrection={yPositionCorrection}
      initial={{ opacity: 0 }}
      animate={
        tooltipSize && tooltipHeightAdjusted ? { opacity: 1 } : { opacity: 0 }
      }
      transition={{ delay: 0.2, duration: 0.5 }}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      onClick={enlargeUsp}
      useInheritPosition={useInheritPosition}
      isTransparentBackground={isPinned && showOnlyMedia}
      offset={mediaContentOffset}
      id={elementId}
      source={source}
    >
      {content}
    </MainWrapper>
  );
};

export default UspSpotTooltip;

import { useState, useEffect, useRef } from 'react';

// Components
import UnitCard from './UnitCard';
import SortUnits from './SortUnits';
import UnitFilter from './unitFilter/UnitFilter';
import PaginationControls from './PaginationControls';

// Helpers
import {
  unitSortableFields,
  createPillFilterValues,
  createRangeFilterValues,
  unitMatchFilter,
  sortUnits
} from './unitListHelpers';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'src/localization/localizer';
import { forBiggerThan } from 'src/helpers/ui';
import { useMobileOrientation } from 'src/hooks/use-mobile-orientation';
import { useDataState } from 'src/store/DataStore';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import isEqual from 'lodash.isequal';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.beigeBg10};
  padding: 0 6%;
  padding-bottom: 32px;
  box-sizing: border-box;
  border-radius: ${isMobileOnly ? '20px 20px 0 0 ' : '0'};
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${isMobileOnly ? `18px 0` : `48px 0 28px`};
`;

const Counter = styled.div<StyledProps<{ alignLeft?: boolean }>>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: ${isMobileOnly ? 1.5 : 1.875}rem;
  color: ${({ theme }) => theme.black};
  b {
    font-style: italic;
    padding-right: 9px;
  }

  padding: ${({ alignLeft }) => (alignLeft || isMobileOnly ? '0' : '0 140px')};
`;

const FilterResult = styled(Counter)`
  font-size: 1.25rem;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  flex-direction: row;
  align-items: center;

  ${forBiggerThan.tablet`
  height: 68px;
  `}

  ${forBiggerThan.desktop`
  padding: 0 216px;`}
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;

  ${forBiggerThan.largeDesktop`
  padding: 0 50px;`}
`;

const HDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.gray20};
  margin: 24px 0 12px;
`;

const TourContainer = styled.div<StyledProps<{ isLandscape: boolean }>>`
  position: relative;
  margin: 0 -6.8%;
  aspect-ratio: ${({ isLandscape }) =>
    !isMobileOnly ? '16 / 6.5' : isLandscape ? '12 / 5' : '9 / 12'};
  overflow: hidden;
  flex-shrink: 0;

  ${isMobileOnly &&
  css`
    margin: 30px -22px 0;
  `}
`;

const ClearAllButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
`;

const Divider = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const DropdownsBlock = styled.div`
  display: flex;
  gap: 20px;
  align-ietms: center;
  justify-content: flex-end;
  margin: 15px 0 20px;

  ${forBiggerThan.tablet`
    flex-direction: row;
    margin: 0 0 0 auto;
  `}
`;

const MOCK_SHOWCASE_CONFIGURATION = {
  type: 'VmShowcaseConfiguration',
  objectId: 'ydQabzZ4O0',
  loaded: true,
  createdAt: 1670325141462,
  lastModifiedAt: 1684221610004,
  entity: {
    type: 'Entity',
    objectId: 'aroundmedia'
  },
  vault: {
    type: 'Vault',
    objectId: 'lBdcADuZrY'
  },
  project: {
    type: 'VmProjectSection',
    objectId: 'VlxgO28uJU',
    parentType: 'Vault',
    parentObjectId: 'lBdcADuZrY'
  },
  showUnitState: true,
  hidePriceWhenShared: false,
  hidePriceWhenUnitSold: true,
  initialTourImageFitFillType: 'fit',
  initialTourShowUnits: true,
  showMapMenu: true,
  showResourceMenu: true,
  showUnitListMenu: true,
  showStartingVideo: false,
  isVerticalScroll: false,
  startingVideoContentItemObjectId: 'e3gZszIImk',
  showUnitPreviewImage: false,
  showUnitDownloadPlanButton: false,
  showUnitContactUsButton: true,
  showUnitCustomFields: false,
  showTurnTable: true,
  embededUnitFormConfiguration: {
    contactUsFormId: {
      textMap: {
        en: 'c9b817d6-0964-407a-8bb6-50ad8476c661'
      }
    },
    contactUsPortalId: '25132586',
    customButtonName: 'test',
    showContactUsFormAsGeneric: false,
    askToFillInFavorites: true,
    contactUsBannerText: 'test d',
    localizedCustomButtonName: {
      textMap: {
        undefined: 'Qqqq Wwww Eeee'
      }
    },
    formType: 'promptoDefaultForm'
  },
  useCustomColors: true,
  showcasePrimaryColor: '#ab0092',
  showcaseSecondaryColor: '#d5ea9c',
  infowallConfiguration: {
    enableInfowall: true,
    infowallText: 'Yo-yo-yo',
    pagesBlockedByInfowall: ['unit', 'map', 'units', 'resources'],
    formType: 'promptoDefaultForm'
  },
  enableUnitClusteringForDesktop: false,
  showStartScreenForEmbeddedView: false,
  onboardingScreenSettings: {
    enableOnboardingDesktop: true
  },
  enableResourcesSections: true,
  imageTourBackgroundType: 'light'
};

export const paginationOptions = [
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
];

const displayMaxOptions = { numberOfBedrooms: 4, numberOfBathrooms: 3 };

// Types
interface UnitListProps {
  title: string;
  units?: any[];
  showcaseConfiguration?: any;
  // weather to display filter options
  withFilters?: boolean;
  // weather to show sorting
  withSorting?: boolean;
  // if defined, pagination should be added
  pageSize?: number;
  // weather to show project tour
  renderProjectTour?: any;
  showBathroomsInDetails?: any;
  showBedroomsInDetails?: any;
  hidePrices: boolean;
  vaultId: string;
  projectId: string;
}

export enum RangeFilterKeys {
  price = 'price',
  surface = 'surface'
}

export enum PillFilterKeys {
  status = 'status',
  numberOfBedrooms = 'numberOfBedrooms',
  numberOfBathrooms = 'numberOfBathrooms'
}

export interface IRangeFilter {
  limits: { min: number; max: number };
  values: { min: number; max: number };
  unit: string;
}

export interface IOptionFilter {
  options: any[];
  values: any[];
}

type RangeFilterType = {
  [key in RangeFilterKeys]: IRangeFilter;
};

type PillsFilterType = {
  [key in PillFilterKeys]: IOptionFilter;
};

export type UnitsFilterType = RangeFilterType & PillsFilterType;

const UnitList = (props: UnitListProps) => {
  const {
    title,
    units,
    showcaseConfiguration = MOCK_SHOWCASE_CONFIGURATION,
    withFilters = false,
    withSorting = false,
    renderProjectTour: RenderProjectTour = null,
    showBathroomsInDetails,
    showBedroomsInDetails,
    hidePrices,
    vaultId,
    projectId
  } = props;

  const prevPage = useRef<number>(0);

  // units
  const [filteredUnits, setFilteredUnits] = useState<any[]>([]);
  const [displayedUnits, setDisplayedUnits] = useState<any[]>([]);

  const { DataStateDispatch } = useDataState();

  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const { filterValues, selectedFilters } = ProjectsFiltersState;

  // filter and sorting
  const [initialFilter, setInitialFilter] = useState<UnitsFilterType>();
  const [filter, setFilter] = useState<UnitsFilterType>();
  const [sortingCriteria, setSortingCriteria] = useState('price');
  const [sortAscending, setSortAscending] = useState(true);
  const [updatedFilterKey, setUpdatedFilterKey] = useState('');

  // pagination
  const [withPagination, setWithPagination] = useState(false);
  const [pageSize, setPageSize] = useState<number>(paginationOptions[0].value);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  // screen orientation
  const { isLandscape } = useMobileOrientation();

  // check if pagination is needed
  useEffect(() => {
    setWithPagination(filteredUnits.length > paginationOptions[0].value);
  }, [filteredUnits]);

  // calculate filter options
  useEffect(() => {
    if (!units) return;
    if (initialFilter) return;

    const calculateFilterData = async () => {
      const prices = new Set();
      const surfaces = new Set();
      const bedrooms = new Set();
      const bathrooms = new Set();
      const unitTypeIds = new Set();
      const statuses = new Set();

      units?.forEach((unit: any) => {
        if (unit.unitMetadata) {
          surfaces.add(unit.unitMetadata.surface);
          bedrooms.add(unit.unitMetadata.numberOfBedrooms);
          bathrooms.add(unit.unitMetadata.numberOfBathrooms);
        }
        // Do not include sold units
        if (unit.unitPrice && unit.state !== 'SOLD') {
          prices.add(unit.unitPrice.price);
        }
        if (unit.unitType) {
          unitTypeIds.add(unit.unitType.title);
        }
        if (unit.state) {
          statuses.add(unit.state);
        }
      });

      const filterObject: any = {
        price: createRangeFilterValues(prices, '€'),
        surface: createRangeFilterValues(surfaces, 'm²'),
        numberOfBedrooms: createPillFilterValues(bedrooms),
        numberOfBathrooms: createPillFilterValues(bathrooms),
        status: createPillFilterValues(statuses)
      };

      const initialFilterObject: any = {
        price: createRangeFilterValues(prices, '€'),
        surface: createRangeFilterValues(surfaces, 'm²'),
        numberOfBedrooms: createPillFilterValues(bedrooms),
        numberOfBathrooms: createPillFilterValues(bathrooms),
        status: createPillFilterValues(statuses)
      };

      // Apply filters set for marketplace
      const unitFilters = ['surface', 'numberOfBedrooms', 'numberOfBathrooms'];
      if (!hidePrices) {
        unitFilters.push('price');
      }
      unitFilters.forEach((filterKey: any) => {
        const isFilterSelected = selectedFilters.find(
          (filter: any) => filter.key === filterKey
        )?.selected;

        if (['price', 'surface'].includes(filterKey) && isFilterSelected) {
          if (
            filterValues[filterKey].values.min <
            filterObject[filterKey].values.max
          ) {
            filterObject[filterKey].values.min = Math.max(
              filterObject[filterKey].values.min,
              filterValues[filterKey].values.min
            );
          }
          filterObject[filterKey].values.max = Math.min(
            filterObject[filterKey].values.max,
            filterValues[filterKey].values.max
          );
        } else if (
          ['numberOfBedrooms', 'numberOfBathrooms'].includes(filterKey) &&
          isFilterSelected
        ) {
          if (filterValues[filterKey].values) {
            filterObject[filterKey].values = filterValues[
              filterKey
            ].values.filter((val: number) =>
              filterObject[filterKey].options.includes(val)
            );
          }
        }
      });

      // We set default filter to only show available units
      if (filterObject.status.values) {
        // @ts-ignore
        filterObject.status.values = ['AVAILABLE'];
        initialFilterObject.status.values = ['AVAILABLE'];
      }

      setInitialFilter(initialFilterObject);
      setFilter(filterObject);
    };

    calculateFilterData();
  }, [units, filterValues]);

  // Updated shared filter values
  useEffect(() => {
    const filterKeys = Object.getOwnPropertyNames(filterValues);
    if (
      !isEqual(filter, initialFilter) &&
      filterKeys.includes(updatedFilterKey)
    ) {
      ProjectsFiltersDispatch({
        type: 'updateFromUnitsFilter',
        payload: { filterObject: filter, filterKey: updatedFilterKey }
      });
    }
  }, [filter, initialFilter, updatedFilterKey, ProjectsFiltersDispatch]);

  useEffect(() => {
    if (units) {
      const localFilteredUnits =
        withFilters && filter
          ? units.filter((unit) =>
              unitMatchFilter(unit, filter, displayMaxOptions)
            )
          : [...units];

      const sortedUnits = sortUnits(
        localFilteredUnits,
        sortingCriteria,
        sortAscending
      );

      setFilteredUnits(sortedUnits);
    }
  }, [units, filter, withFilters, sortingCriteria, sortAscending]);

  // calculate units to display
  useEffect(() => {
    if (pageSize) {
      setDisplayedUnits(
        filteredUnits.slice(pageSize * page, pageSize * page + pageSize)
      );
    } else {
      setDisplayedUnits(filteredUnits);
    }
  }, [filteredUnits, pageSize, page]);

  useEffect(() => {
    // if page wasn't changed - that means that only filters changed - then go to first page
    if (prevPage.current === page) {
      setPage(0);
    }
    prevPage.current = page;
  }, [filteredUnits, page]);

  const onCardClick = (unitObjectId: string) => {
    DataStateDispatch({
      type: 'setScrollToTopOfUnitPage',
      payload: true
    });
    navigate(
      `/vault/${vaultId}/project/${projectId}/unit/${unitObjectId}` +
        window.location.search
    );
  };

  const clearButtonEnabled = !isEqual(filter, initialFilter);

  return (
    <Wrapper id={'unitList'}>
      <Header>
        <Counter alignLeft={withFilters} id="unitTitle">
          <b>{units?.length ?? 0}</b> {title}
        </Counter>

        {!withFilters && RenderProjectTour && (
          <TourContainer isLandscape={isLandscape}>
            <RenderProjectTour unitList={filteredUnits} />
          </TourContainer>
        )}

        {withFilters && filter && (
          <>
            {!isMobileOnly && <HDivider />}
            <UnitFilter
              updateActiveFilter={(filterName, filterValues) => {
                setFilter((filter: any) => ({
                  ...filter,
                  [filterName]: filterValues
                }));
                setUpdatedFilterKey(filterName);
              }}
              filterOptions={filter}
              resetFilters={() => setFilter(initialFilter)}
              applyFilters={setFilter}
              initialFilter={initialFilter}
              showBathroomsInDetails={showBathroomsInDetails}
              showBedroomsInDetails={showBedroomsInDetails}
              hidePrices={hidePrices}
              displayMax={displayMaxOptions}
            />

            {RenderProjectTour && (
              <TourContainer isLandscape={isLandscape}>
                <RenderProjectTour unitList={filteredUnits} />
              </TourContainer>
            )}

            <FilterResult>
              <div>
                <b>{filteredUnits.length}</b>{' '}
                {
                  localizer.unitListComponent[
                    isMobileOnly ? 'units' : 'unitsFoundByFilters'
                  ]
                }
              </div>

              {clearButtonEnabled && <Divider>{` - `}</Divider>}

              {clearButtonEnabled && (
                <ClearAllButton onClick={() => setFilter(initialFilter)}>
                  {localizer.unitListComponent.clearAll}
                </ClearAllButton>
              )}

              <DropdownsBlock>
                {withSorting && (
                  <SortUnits
                    fields={unitSortableFields}
                    currentFieldToSort={sortingCriteria}
                    onSortByChanged={(
                      sortOnField: string,
                      isAscending: boolean
                    ) => {
                      setSortingCriteria(sortOnField);
                      setSortAscending(isAscending);
                    }}
                    isSortAscending={sortAscending}
                    withPagination={withPagination}
                    showBathroomsInDetails={showBathroomsInDetails}
                    showBedroomsInDetails={showBedroomsInDetails}
                  />
                )}
              </DropdownsBlock>
            </FilterResult>
          </>
        )}
      </Header>

      <CardList>
        {displayedUnits.map((unit) => (
          <UnitCard
            key={unit.objectId}
            onCardClick={onCardClick}
            unit={unit}
            showcaseConfiguration={showcaseConfiguration}
            hidePrices={hidePrices}
          />
        ))}

        {withPagination && (
          <PaginationControls
            perPage={pageSize}
            setPerPage={setPageSize}
            currentPage={page}
            setCurrentPage={setPage}
            totalEntries={filteredUnits?.length ?? 0}
            toPreviousPage={() => setPage((page) => page - 1)}
            toNextPage={() => setPage((page) => page + 1)}
          />
        )}
      </CardList>
    </Wrapper>
  );
};

export default UnitList;
